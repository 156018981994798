import { React, Container, DataGrid, Pagination, TableColumns, Helper } from "common";
import { CommonApi } from "services";

import AddLocationPopup from "./addLocation";

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [statusList, setStatusList] = React.useState([]);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [curPage, setCurPage] = React.useState(1);
    const [pageSize] = React.useState(10);
    const [addLocation, setAddLocation] = React.useState(false);
    const [stateFilter, setStateFilter] = React.useState("");
    const [stateFilterName, setStateFilterName] = React.useState("");

    const FetchResults = async () => {
        global.Busy(true);

        let rslt = await CommonApi.GetStatusTypes();
        if (rslt.status === 100) {
            let _tmp = rslt.data || [];
            _tmp.splice(0, 0, { _id: 0, keyid: 0, name: "Select" });
            setStatusList(_tmp);
        }

        FilterRows();

        global.Busy(false);
    };

    const FilterRows = async () => {
        global.Busy(true);
        setRow([]);
        setRowsCount(0);

        let filters = {};

        if (!Helper.IsNullValue(stateFilter) && parseInt(stateFilter) > 0) {
            filters = { statusId: stateFilter };
        }

        if (!Helper.IsNullValue(stateFilterName)) {
            filters = { name: stateFilterName };
        }

        let req = { page: curPage, size: pageSize, filters };

        let rslt = await CommonApi.Locations(req);
        if (rslt.status === 100) {
            let _tmp = rslt.data;
            setRowsCount(_tmp.recordCount);
            setRow(_tmp.rows);
        }
        global.Busy(false);
    }

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        setInitlized(true);
    }, [curPage]);

    const OnPageChange = async (e) => {
        setCurPage(e);
        setInitlized(true);
    }

    const OnAddLocationClicked = (e) => {
        e.preventDefault();
        setAddLocation(true);
    }

    const OnAddLocation = (e) => {
        setAddLocation(false);
        if (e) FilterRows();
    }

    const OnActionClicked = async (e, name, data) => {
        e.preventDefault();
        global.Busy(true);
        let req = { _id: data._id, statusId: name === 'revoke' ? 2 : 1, update: true };
        await CommonApi.Locations(req);
        setCurPage(1);
        setStateFilter("");
        setStateFilterName("");
        global.Busy(false);
        FilterRows();

    }

    const OnSearchClicked = (e) => {
        e.preventDefault();
        FilterRows();
    }

    return (
        <Container {...props}>
            <div className="generic_headblk">
                <div className="blkName">Locations</div>
                <div className="control_bar">
                    <div className="controlBarHeader">Filter By Status:</div>
                    <div className="controlBarSelectField">
                        <select name="filterType" value={stateFilter} onChange={(e) => setStateFilter(e.target.value)}>
                            {statusList.map((x) => (
                                <option key={x.keyid} value={x.keyid}>{x.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="controlBarHeader">Search By Name:</div>
                    <div className="controlBarTxtField">
                        <input type="text" value={stateFilterName} onChange={(e) => setStateFilterName(e.target.value)} />
                    </div>
                    <div className="mcpl_nav_btn">
                        <input type="button" value="Search" className="mcpl_btn"
                            onClick={(e) => OnSearchClicked(e)} />
                    </div>
                    <div className="mcpl_nav_btn">
                        <input type="button" value="Add New Location" className="mcpl_btn"
                            onClick={(e) => OnAddLocationClicked(e)}
                        />
                    </div>
                    <div className="controlPagination">
                        {rowsCount > 0 &&
                            <Pagination onPageChange={OnPageChange} className="pagination-bar"
                                currentPage={curPage} rowsCount={rowsCount} pageSize={pageSize} />
                        }
                    </div>
                </div>
            </div>

            <div className="contentarea_table">
                <DataGrid dataColumns={TableColumns.LocationColumns}
                    curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize} buttonstatusid="statusName"
                    onIconClicked={OnActionClicked}
                    rowsCount={rowsCount} dataRows={row} />
            </div>
            <AddLocationPopup show={addLocation} onHideClicked={OnAddLocation} />
        </Container>
    );
};

export default Component;