import { React, Helper, DropDown, TextInput } from "common";
import PopupDialog from "screens/popups";

import { CommonApi, UsersApi } from "services";

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const { show, data, onHideClicked, update } = props;
    const [row, setRow] = React.useState({});
    const [error, setError] = React.useState(null);
    const [locations, setLocations] = React.useState([]);
    const [userTypes, setUserTypes] = React.useState([]);
    const [state, setState] = React.useState(false);
    const [errors, setErrors] = React.useState([]);

    const ResetValues = () => {
        setError("");
        setErrors([]);
        setRow({});
    }

    const OnCancelClicked = (e) => {
        e.preventDefault();
        if (onHideClicked) {
            ResetValues();
            onHideClicked(false);
        }
    }

    const OnAddClicked = async (e) => {
        e.preventDefault();
        setError(null);
        if (!OnRecordValidate()) return;
        row.add = true;
        global.Busy(true);
        let rslt = await UsersApi.AddUsers(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        setRow({});
        if (onHideClicked) {
            ResetValues();
            onHideClicked(true);
        }
    }

    const OnBlurError = (name, val, clear) => {
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('userDialog');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const buttons = [{ label: "Add", event: OnAddClicked }, { label: "Cancel", event: OnCancelClicked }];

    const FetchResults = async () => {
        global.Busy(true);
        setUserTypes([]);
        setLocations([]);
        let rslt = await CommonApi.GetUserTypes();
        if (rslt.status === 100) {
            let _tmp = rslt.data || [];
            _tmp.splice(0, 0, { _id: 0, typeId: 0, name: "Select" });
            _tmp = _tmp.filter((x) => parseInt(x.typeId) !== 1);
            setUserTypes(_tmp);
        }

        const req = { nopaging: true, filters: { statusId: 1 } };
        rslt = await CommonApi.Locations(req);
        if (rslt.status === 100) {
            let _tmp = rslt?.data?.rows || [];
            _tmp.splice(0, 0, { _id: 0, name: "Select" });
            setLocations(_tmp);
        }

        global.Busy(false);
    };

    if (initlized) {
        ResetValues();
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        let tmp = data || {};
        setRow(tmp);
    }, [data]);

    if (!show) return null;


    return (
        <>
            <PopupDialog error={error} id={"addUserPopup"} title={update ? "Update User" : "Add New User"} buttons={buttons} width={550} height={300} >
                <div className="contentarea_table" style={{ border: 0 }} id="userDialog">
                    <ul className="contentIIcol">
                        <li className="contentIIcol">
                            <div className="formControlHead">Type<div className="formMandatory">*</div></div>
                            <div className="formControlSelectField">
                                <DropDown
                                    path={`typeId`}
                                    value={row?.typeId}
                                    onInputChildChanged={OnInputChanged}
                                    items={userTypes}
                                    displayName={"name"}
                                    displayValue={"typeId"}
                                    errors={errors}
                                    dataRequired={true}
                                    indexValue={1}
                                    requiredMessage={"Type is required"}
                                />
                            </div>
                        </li>
                        <li className="contentIIcol">
                            <div className="formControlHead">Name<div className="formMandatory">*</div></div>
                            <div className="formControlTxtField">
                                <TextInput
                                    path={`borrowerName`}
                                    value={row?.borrowerName}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    errors={errors}
                                    requiredMessage={'Name is required'}
                                    dataRequired={true}
                                    required={true}
                                    placeholder="User Name"
                                />
                            </div>
                        </li>
                        <li className="contentIIcol">
                            <div className="formControlHead">Email<div className="formMandatory">*</div></div>
                            <div className="formControlTxtField">
                                <TextInput
                                    path={`emailId`}
                                    value={row?.emailId}
                                    valuetype={"EMAIL"}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    errors={errors}
                                    requiredMessage={'Email-Id is required'}
                                    validationMessage={'Enter valid Email-Id'}
                                    dataRequired={true}
                                    required={true}
                                    validate={true}
                                    placeholder="Email Address"
                                />
                            </div>
                        </li>
                        <li className="contentIIcol">
                            <div className="formControlHead">Phone<div className="formMandatory">*</div></div>
                            <div className="formControlTxtField">
                                <TextInput
                                    path={`phoneNo`}
                                    value={row?.phoneNo}
                                    valuetype={"NUMBERS"}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    errors={errors}
                                    requiredMessage={'Phone is required'}
                                    validationMessage={'Enter valid Phone Number'}
                                    dataRequired={true}
                                    required={true}
                                    validate={true}
                                    placeholder="Phone Number"
                                />
                            </div>
                        </li>
                        <li className="contentIIcol">
                            <div className="formControlHead">Location<div className="formMandatory">*</div></div>
                            <div className="formControlSelectField">
                                <DropDown
                                    path={`locationId`}
                                    value={row?.locationId}
                                    onInputChildChanged={OnInputChanged}
                                    items={locations}
                                    displayName={"name"}
                                    displayValue={"_id"}
                                    errors={errors}
                                    dataRequired={true}
                                    indexValue={1}
                                    requiredMessage={"Location is required"}
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </PopupDialog>
        </>
    );
};
export default Component;
