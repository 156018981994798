import { React, Helper } from "common";
import { CommonApi } from "services";
import PopupDialog from "screens/popups";


const Component = (props) => {

    const { show, data, onHideClicked } = props;
    const [error, setError] = React.useState(null);
    const [locationName, setLocationName] = React.useState("");

    const OnCancelClicked = (e) => {
        e.preventDefault();
        if (onHideClicked) onHideClicked(false);
    }

    const OnAddClicked = async (e) => {
        e.preventDefault();
        setError(null);
        if (Helper.IsNullValue(locationName)) {
            setError("Location name is required");
            return;
        }
        let req = {
            add: true,
            name: locationName
        }
        global.Busy(true);
        let rslt = await CommonApi.Locations(req);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        if (onHideClicked) onHideClicked(true);
    }

    const buttons = [{ label: "Add", event: OnAddClicked }, { label: "Cancel", event: OnCancelClicked }];

    if (!show) return null;

    return (
        <>
            <PopupDialog error={error} id={"addLocationPopup"} title={"Add New Location"} buttons={buttons} width={350} >
                <div className="contentarea_table" style={{ border: 0 }} id="locationExecute">
                    <ul className="formControl">
                        <li className="formControl">
                            <div className="formControlHead">Name<div className="formMandatory">*</div></div>
                            <div className="formControlTxtField">
                                <input type="text" placeholder="Location Name" value={locationName} onChange={(e) => setLocationName(e.target.value)} />
                            </div>
                        </li>
                    </ul>
                </div>
            </PopupDialog>
        </>
    );
};
export default Component;
