
const LoanColumns = [
    { name: "_id", type: "key" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "loanNumber", type: "text", label: "Account Number", width: "12%" },
    { name: "startDate", type: "date", label: "Date", width: "10%" },
    { name: "customerName", type: "text", label: "Customer Name", width: "24%" },
    { name: "customerMobile", type: "number", label: "Customer Mobile", width: "10%" },
    { name: "loanAmount", type: "decimal", label: "Loan Amount", width: "10%" },
    { name: "emi", type: "decimal", label: "EMI Amount", width: "10%" },
    { name: "tenure", type: "number", label: "Tenure", width: "5%" },
    { name: "balanceAmount", type: "decimal", label: "Balance", width: "8%" },
    { name: "statusName", type: "text", label: "Status", width: "6%" }
];

const LoanTransColumns = [
    { name: "_id", type: "key" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "loanac", type: "text", label: "Account Number", width: "10%" },
    { name: "custname", type: "text", label: "Customer Name", width: "40%" },
    { name: "custmobile", type: "number", label: "Customer Mobile", width: "8%" },
    { name: "loanamt", type: "decimal", label: "Loan Amount", width: "8%" },
    { name: "balance", type: "decimal", label: "Balance", width: "7%" },
    { name: "loanon", type: "date", label: "Date", width: "7%" },
    { name: "loantenure", type: "number", label: "Tenure", width: "4%" },
    { name: "emioverdue", type: "number", label: "OverDue", width: "5%" },
    { name: "statusname", type: "text", label: "Status", width: "6%" }
];

const TeamColumns = [
    { name: "_id", type: "key" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "teamname", type: "text", label: "Team Name", width: "83%" },
    { name: "statusname", type: "text", label: "Status", width: "6%" },
    { name: "action", type: "delete", label: "", width: "6%" }
];

const LocationColumns = [
    { name: "_id", type: "key" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "name", type: "text", label: "Location Name", width: "83%" },
    { name: "statusName", type: "text", label: "Status", width: "6%" },
    {
        name: "action", type: "hidden", width: "6%",
        icons: ["fa fa-check", "fa fa-close"],
        names: ["active", "revoke"],
        tips: ["Activate", "Revoke"],
        disabled: ["Active", "De-Active"],
    }
];

const TeamAgentColumns = [
    { name: "_id", type: "key" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "teamname", type: "text", label: "Team Name", width: "33%" },
    { name: "agentname", type: "text", label: "Agent Name", width: "33%" },
    { name: "mobile", type: "text", label: "Mobile", width: "9%" },
    { name: "notifystatustext", type: "text", label: "Notification", width: "8%" },
    { name: "statusname", type: "text", label: "Status", width: "6%" },
    { name: "action", type: "delete", label: "", width: "6%" }
];

const TeamLoanColumns = [
    { name: "_id", type: "key" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "teamname", type: "text", label: "Team Name", width: "41%" },
    { name: "loanac", type: "text", label: "Loan Account", width: "41%" },
    { name: "statusname", type: "text", label: "Status", width: "7%" },
    { name: "action", type: "delete", label: "", width: "6%" }
];

const UserColumns = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "agentId", type: "link", label: "Id", width: "7%" },
    { name: "typeName", type: "text", label: "Type", width: "10%" },
    { name: "name", type: "text", label: "Name", width: "auto" },
    { name: "phoneNo", type: "number", label: "Mobile", width: "6%" },
    { name: "emailId", type: "text", label: "Email", width: "15%" },
    { name: "locationName", type: "text", label: "Location", width: "10%" },
    { name: "statusName", type: "text", label: "Status", width: "5%" },
    {
        name: "action", type: "hidden", width: "6%",
        icons: ["fa fa-check", "fa fa-close"],
        names: ["active", "revoke"],
        tips: ["Activate", "Revoke"],
        disabled: ["Active", "De-Active"],
    }
];

const NachColumns = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "loanNumber", type: "text", label: "Loan Number", width: "8%" },
    { name: "payerName", type: "text", label: "Payer Name", width: "15%" },
    { name: "payerAddress", type: "text", label: "Payer Address", width: "15%" },
    { name: "pattern", type: "text", label: "Frequency", width: "5%" },
    { name: "amount", type: "number", label: "EMI Amount", width: "6%" },
    { name: "startDate", type: "text", label: "Start Date", width: "6%" },
    { name: "endDate", type: "text", label: "End Date", width: "6%" },
    { name: "txnId", type: "text", label: "TxnId", width: "17%" },
    { name: "status", type: "text", label: "Status", width: "6%" },
    {
        name: "action", type: "buttons", width: "6%",
        icons: ["fa fa-pencil", "fa fa-inr", "fa fa-ban"],
        names: ["modify", "execute", "revoke"],
        tips: ["Modify", "Execute", "Revoke"],
        disabled: ["REVOKE,REVOKED,PENDING", "REVOKE,REVOKED,PENDING", "REVOKE,REVOKED,PENDING"],
    }
];

const ValidationColumns = [
    { name: "BorrowerCode", type: "text", label: "Borrower Code", width: "10%" },
    { name: "BorrowerName", type: "text", label: "Borrower Name", width: "20%" },
    { name: "Description", type: "text", label: "Validation Message", width: "70%" }
];

const AgentValidationColumns = [
    { name: "finstaReference", type: "text", label: "Finsta Reference", width: "30%" },
    { name: "Description", type: "text", label: "Validation Message", width: "70%" }
];

const ImportDocColumns = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "name", type: "text", label: "Name", width: "10%" },
    { name: "path", type: "text", label: "File", width: "28%" },
    { name: "description", type: "text", label: "Description", width: "28%" },
    { name: "statusName", type: "text", label: "Status", width: "11%" },
    { name: "createdAt", type: "datetime", label: "Imported Date/Time", width: "18%" }
];

const LoanListColumns = [
    { name: "index", type: "checkbox", label: "", width: "2%" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: 'loanNumber', type: "text", label: 'Loan Number', width: "8%" },
    { name: 'loanType', type: "text", label: 'Loan Type', width: "8%" },
    { name: 'customerName', type: "text", label: 'Borrower Name', width: "25%" },
    { name: 'customerMobile', type: "text", label: 'Borrower Phone', width: "8%" },
    { name: 'agentName', type: "text", label: 'Agent Name', width: "25%" },
    { name: 'agentPhoneNo', type: "text", label: 'Agent Phone', width: "8%" },
    { name: 'agentLocation', type: "text", label: 'Agent Location', width: "8%" }
];

const AgentColumns = [
    { name: "index", type: "radio", label: "", width: "2%", groupname: "agent_select" },
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "name", type: "text", label: "Name", width: "15%" },
    { name: "phoneNo", type: "text", label: "Phone", width: "8%" },
    { name: "locationName", type: "text", label: "Location", width: "8%" }
];

const OtpVerificationColumns = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "borrowerName", type: "text", label: "Borrower Name", width: "25%" },
    { name: "loanNumber", type: "text", label: "Loan Number", width: "25%" },
    { name: "otpnum", type: "text", label: "OTP", width: "20%" },
    { name: "updatedAt", type: "datetime", label: "OTP Time", width: "25%" }
];

const LoanReports = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "name", type: "text", label: "Agent Name", width: "10%" },
    { name: "collections", type: "NumberLink", label: "Total Collections", width: "10%" },
    { name: "customersVisited", type: "NumberLink", label: "Customers Visited", width: "10%" },
    { name: "collectedAmount", type: "INRLink", label: "Amt. Collected", width: "10%" },
    { name: "assignedCollections", type: "NumberLink", label: "Assigned Collections", width: "13%" },
    { name: "hoVerifiedAmount", type: "INRLink", label: "Verified Amount", width: "10%" },
    { name: "reconciledAmount", type: "INRLink", label: "Reconciled Amount", width: "10%" },
    { name: "hoRejectedAmount", type: "INRLink", label: "Rejected Amount", width: "10%" },
    { name: "rejectedCollections", type: "NumberLink", label: "Rejected", width: "6%" },
    { name: "revokedCollections", type: "NumberLink", label: "Revoked", width: "6%" }
];

const AgentImportColumns = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "name", type: "text", label: "Agent Name", width: "10%" },
    { name: "path", type: "text", label: "File", width: "28%" },
    { name: "description", type: "text", label: "Description", width: "28%" },
    { name: "statusName", type: "text", label: "Status", width: "11%" },
    { name: "createdAt", type: "datetime", label: "Imported Date/Time", width: "18%" }
];

const OTPReports = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "customerName", type: "text", label: "Customer Name", width: "20%" },
    { name: "customerPhone", type: "text", label: "Customer Phone", width: "15%" },
    { name: "loanNumber", type: "text", label: "Loan Number", width: "10%" },
    { name: "emiAmount", type: "INR", label: "Emi Amount", width: "10%" },
    { name: "agentName", type: "text", label: "Agent Name", width: "20%" },
    { name: "updatedAt", type: "datetime", label: "OTP Date/Time", width: "20%" }

];

const CollectedReports = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "agentName", type: "text", label: "Agent Name", width: "15%" },
    { name: "loanNumber", type: "text", label: "Loan Number", width: "15%" },
    { name: "borrowerName", type: "text", label: "Customer Name", width: "15%" },
    { name: "totalAmtReceived", type: "INR", label: "Amount Received", width: "10%" },
    { name: "receivedDate", type: "date", label: "Received Date", width: "10%" },
    { name: "hoverified", type: "boolean", label: "Verified", width: "10%" },
    { name: "reconcile", type: "boolean", label: "Reconciled", width: "10%" },
    { name: "finstaStatus", type: "boolean", label: "Finsta Uploaded", width: "10%" }
];


const NonCollectedReports = [
    { name: "index", type: "index", label: "Sl. No", width: "5%" },
    { name: "agentName", type: "text", label: "Agent Name", width: "25%" },
    { name: "loanNumber", type: "text", label: "Loan Number", width: "25%" },
    { name: "borrowerName", type: "text", label: "Customer Name", width: "25%" },
    { name: "emiAmount", type: "INR", label: "EMI", width: "20%" },
];

module.exports = {
    LoanColumns, LoanTransColumns, ImportDocColumns, AgentColumns, TeamColumns, TeamLoanColumns, TeamAgentColumns, LoanListColumns,
    UserColumns, NachColumns, ValidationColumns, LocationColumns, OtpVerificationColumns, LoanReports, AgentImportColumns,
    AgentValidationColumns, OTPReports, CollectedReports, NonCollectedReports
}