import { React, DataGrid, Pagination, Container, TableColumns } from "common";
import { UpiApi } from "services";
import { RenderRevoke, RenderModify, RenderExecute } from "./childs";

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [curPage, setCurPage] = React.useState(1);
    const [pageSize] = React.useState(10);
    const [showRevoke, setShowRevoke] = React.useState(false);
    const [showModify, setShowModify] = React.useState(false);
    const [showExecute, setShowExecute] = React.useState(false);

    const [popupData, setPopupData] = React.useState({});

    const FetchResults = async () => {
        global.Busy(true);
        setRow([]);
        setRowsCount(0);
        let req = { page: curPage, size: pageSize };
        let rslt = await UpiApi.GetMandateList(req);
        if (rslt.status === 100) {
            let _tmp = rslt.data;
            setRowsCount(_tmp.recordCount);
            setRow(_tmp.rows);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        setInitlized(true);
    }, [curPage]);

    const OnPageChange = async (e) => {
        setCurPage(e);
        setInitlized(true);
    }

    const OnIconClicked = (e, name, data) => {
        e.preventDefault();
        setPopupData(data);
        if (name === "revoke") setShowRevoke(true);
        if (name === "modify") setShowModify(true);
        if (name === "execute") setShowExecute(true);
    }

    const OnHideClicked = (name) => {
        if (name === "revoke") setShowRevoke(false);
        if (name === "modify") setShowModify(false);
        if (name === "execute") setShowExecute(false);
        setInitlized(true);
    }

    return (
        <Container {...props}>
            <div className="generic_headblk">
                <div className="blkName">E-Mandate List </div>
                {rowsCount > 0 &&
                    <Pagination onPageChange={OnPageChange} className="pagination-bar"
                        currentPage={curPage} rowsCount={rowsCount} pageSize={pageSize} />
                }
            </div>

            <div className="contentarea_table">
                <DataGrid id="lnach" dataColumns={TableColumns.NachColumns} buttonstatusid="status"
                    curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize}
                    rowsCount={rowsCount} dataRows={row} onIconClicked={OnIconClicked} />
            </div>

            {showModify && <RenderModify onHideClicked={OnHideClicked} data={popupData} />}
            {showRevoke && <RenderRevoke onHideClicked={OnHideClicked} data={popupData} />}
            {showExecute && <RenderExecute onHideClicked={OnHideClicked} data={popupData} />}

        </Container>
    );
};

export default Component;