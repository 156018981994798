import {
    React, Link, useNavigate,
    useTimerSession, Session
} from "common";

const Component = () => {
    const [name] = useTimerSession('user');
    const NavigateTo = useNavigate();

    const OnLogOutClicked = (e) => {
        e.preventDefault();
        Session.Remove("user");
        Session.Remove("userToken");
        Session.Remove("role");
        NavigateTo("/");
    }

    return (
        <>
            <header id="headerInpage">
                <div className="navRight">
                    <Link to="#" title="Settings"><div className="settingicon"></div></Link>
                    <Link to="#" title="Tasks"><div className="alerticon"></div></Link>

                    <ul className="rightAccountBlk">
                        <li className="rightAccountBlk">{name}</li>
                        <li className="rightAccountBlk">
                            <div className="accountnav_mini" id="myAccountnav">
                                <div className="dropdownAcc_mini">
                                    <button className="dropbtnAcc_mini">Process Associate <div className="arrow_down_mini"></div></button>
                                    <div className="dropdown-content_mini">
                                        <Link to="#" onClick={(e) => OnLogOutClicked(e)}><div className="logoutIcon"></div>Logout</Link>
                                    </div>
                                </div>
                                <Link to="#" className="icon"></Link>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="navBlock">
                    <div className="topnav" id="myTopnav">
                        <Link to="#"><div className="logoPosResponsive mcplLogoWhite"></div></Link>
                        <div className="appDesc">Collection Management System</div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Component;