import { React, Pagination, Helper, TableColumns, DataGrid, DatePicker, DropDown } from "common";
import { ReportsApi } from "services";

const Component = ({ agentsList }) => {

    const [initialize, setInitialize] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [row, setRow] = React.useState({});
    const [isGetreport, setIsGetreport] = React.useState(false);
    const [state, setState] = React.useState(false);
    const [agents] = React.useState(agentsList);
    const [totalAmount, setTotalAmount] = React.useState("");

    const [rowsCount, setRowsCount] = React.useState(10);
    const [curPage, setCurPage] = React.useState(1);
    const [pageSize] = React.useState(10);
    const [error, setError] = React.useState(null);

    const OnGetReportClicked = (e, download) => {
        setError(null);

        if (Helper.IsNullValue(row.agentId)) {
            setError("Please select Agent");
            return;
        }

        if (Helper.IsNullValue(row.fromDate)) {
            setError("Please select From Date");
            return;
        }

        if (Helper.IsNullValue(row.toDate)) {
            setError("Please select to Date");
            return;
        }

        if (row.fromDate > row.toDate) {
            setError("Please select valid Date Range");
            return;
        }

        FilterRows(download);
    }

    const OnInputChanged = async (path, value, index) => {
        setError(null);
        setIsGetreport(false);
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const FilterRows = async (download) => {
        setRows({});
        setIsGetreport(false);

        let filters = [{ status: 1 }], req = {};

        let _row = row;
        if (_row) {
            for (const key in _row) {
                if (Object.hasOwnProperty.call(_row, key) && !Helper.IsNullValue(_row[key])) {
                    filters.push({ name: key, value: _row[key] });
                }
            }
        }

        if (download) {
            req = { filters, environment: Helper.environment, download };
        } else {
            req = { page: curPage, size: pageSize, filters, download };
        }

        global.Busy(true);
        let rslt = await ReportsApi.GetAgentCollectedReport(req);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        if (download) {
            let filePath = rslt.file;
            await DownLoadExcel(filePath);
            setRowsCount(10);
        } else {
            let _tmp = rslt.data;
            setRows(_tmp.rows);
            setRowsCount(_tmp.recordCount);
            setTotalAmount(_tmp.totalAmount);
            setIsGetreport(true);
        }

    }

    const DownLoadExcel = async (filePath) => {
        await fetch(filePath)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "Report.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    const OnPageChange = async (e) => {
        setCurPage(e);
        setInitialize(true);
    }

    if (initialize) {
        setInitialize(false);
        OnGetReportClicked();
    }

    React.useEffect(() => {
        let _cur = curPage;
        if (curPage > 1) {
            _cur = ((curPage - 1) * pageSize) + 1;
        }
    }, [curPage, pageSize]);

    React.useEffect(() => { setInitialize(true); }, []);

    return (
        <>
            <div className="generic_headblk">
                <div className="blkName">Collection Report</div>
                <div className="control_bar">
                    <div className="controlBarHeader">Agent Name:</div>
                    <div className="controlBarSelectField">
                        <DropDown
                            path={`agentId`}
                            value={row?.agentId}
                            onInputChildChanged={OnInputChanged}
                            items={agents}
                            displayName={"name"}
                            displayValue={"_id"}
                            dataRequired={false}
                            indexValue={1}
                        />
                    </div>
                    <div className="controlBarHeader">From Date:</div>
                    <div className="controlBarTxtField">
                        <DatePicker
                            id="fromDate"
                            name="fromDate"
                            value={Helper.ToString(row?.fromDate)}
                            formatDate={'DD-MM-YYYY'}
                            onInputChildChanged={OnInputChanged}
                            required={false}
                            requiredMessage={'Date is required'}
                            validation={false}
                            validationMessage={'Provide valid date'}
                            placeholder={'DD-MM-YYYY'}
                        />
                    </div>
                    <div className="controlBarHeader">To Date:</div>
                    <div className="controlBarTxtField">
                        <DatePicker
                            id="toDate"
                            name="toDate"
                            value={Helper.ToString(row?.toDate)}
                            formatDate={'DD-MM-YYYY'}
                            onInputChildChanged={OnInputChanged}
                            required={false}
                            requiredMessage={'Date is required'}
                            validation={false}
                            validationMessage={'Provide valid date'}
                            placeholder={'DD-MM-YYYY'}
                        />
                    </div>
                    <div className="mcpl_nav_btn">
                        <input type="button" value="Get Report" className="mcpl_btn"
                            onClick={(e) => OnGetReportClicked(e, false)}
                        />
                    </div>
                    <div className="mcpl_nav_btn">
                        <input type="button" value="Download Report" className="mcpl_btn"
                            onClick={(e) => OnGetReportClicked(e, true)}
                        />
                    </div>
                    <div className="controlPagination">
                        {rowsCount > 0 &&
                            <Pagination onPageChange={OnPageChange} className="pagination-bar"
                                currentPage={curPage} rowsCount={rowsCount} pageSize={pageSize} />
                        }
                    </div>
                </div>

                {isGetreport &&
                    (<div className="control_bar">
                        <div className="controlBarHeader">
                            Report From : {Helper.ToDateFormat(row?.fromDate, "DD/MM/YYYY")} to  {Helper.ToDateFormat(row?.toDate, "DD/MM/YYYY")}
                            &nbsp; and Total Received Amount : {Helper.INRCurrencyFormat(totalAmount)}
                        </div>
                    </div>
                    )}

                {error && (
                    <ul className="mcpl_btn_blk">
                        <li className="mcpl_btn_successError" >
                            <div className="errormessage">{error}</div>
                        </li>
                    </ul>
                )}
            </div>

            {rows && rows.length > 0 && (
                <div className="contentarea_table">
                    <DataGrid dataColumns={TableColumns.CollectedReports}
                        curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize} rowsCount={rowsCount} dataRows={rows} />
                </div>
            )}
        </>
    )
}

export default Component;