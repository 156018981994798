import { React, Container, DataGrid, Pagination, TableColumns } from "common";
import { UsersApi } from "services";
import AddUserPopup from "./addUser";

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [curPage, setCurPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [addUser, setAddUser] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});

    const FetchResults = async () => {
        global.Busy(true);
        setRow([]);
        setRowsCount(0);
        let req = { page: curPage, size: pageSize };
        let rslt = await UsersApi.GetUsers(req);
        if (rslt.status === 100) {
            let _tmp = rslt.data;
            setRowsCount(_tmp.recordCount);
            setRow(_tmp.rows);
        }
        global.Busy(false);
    };

    const OnPageChange = async (e) => {
        setCurPage(e);
        setInitlized(true);
    }

    const OnAddUserClicked = (e) => {
        e.preventDefault();
        setUpdate(false);
        setAddUser(true);
    }

    const OnAddUserAction = (e) => {
        if (e) { FetchResults(); }
        setAddUser(false);
        setUpdate(false);
        setSelectedItem({});
    }

    const OnIconClicked = async (e, name, data) => {
        global.Busy(true);
        let req = { _id: data._id, statusId: name === 'revoke' ? 2 : 1 };
        await UsersApi.AddUsers(req).then(() => {
            global.Busy(false);
            setInitlized(true);
        }).catch((err) => {
            global.Busy(false);
            console.log(err);
        });

    }

    const OnLinkClicked = (e, data) => {
        setSelectedItem(data);
        setUpdate(true);
        setAddUser(true);
    }

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        setInitlized(true);
    }, [curPage]);

    return (
        <Container {...props}>
            <div className="generic_headblk">
                <div className="blkName">User Accounts</div>
                <div className="mcpl_nav_btn">
                    <input type="button" value="Add New User" className="mcpl_btn"
                        onClick={(e) => OnAddUserClicked(e)}
                    />
                </div>
                {rowsCount > 0 &&
                    <Pagination onPageChange={OnPageChange} className="pagination-bar"
                        currentPage={curPage} rowsCount={rowsCount} pageSize={pageSize} />
                }
            </div>

            <div className="contentarea_table">
                <DataGrid dataColumns={TableColumns.UserColumns}
                    curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize} buttonstatusid="statusName"
                    onIconClicked={OnIconClicked} onLinkClicked={OnLinkClicked} rowsCount={rowsCount} dataRows={row} />
            </div>

            <AddUserPopup show={addUser} update={update} data={selectedItem} onHideClicked={OnAddUserAction} />
        </Container>
    );
};

export default Component;