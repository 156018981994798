import API from "./api";
import { Session } from "common";

const GetUserTypes = async () => {
    try {
        const token = await Session.GetHeader();
        const res = await API.get(`common/usertypes`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetStatusTypes = async () => {
    try {
        const token = await Session.GetHeader();
        const res = await API.get(`common/statustypes`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const Locations = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`common/locations`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetOtps = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`common/otps`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetUserTypes, GetStatusTypes, Locations, GetOtps };
