const Component = ({ children, onHideClicked, title, width, agent }) => {

    width = width || "auto";

    const OnHideClicked = (e) => {
        if (onHideClicked) onHideClicked(e);
    }

    return (
        <>
            <div id="alertDialog" className="pop_disable_bg" style={{ display: "block" }}>
                <div className="popup_dialog" style={{ width }}>
                    <div className="pop_head">{title}</div>
                    <div className="pop_contentArea" id="alertContent">
                        {`${children} ${agent}`}
                    </div>
                    <div className="pop_buttonLabel">
                        <input
                            type="button"
                            value={"Yes"}
                            className="pop_button"
                            onClick={(e) => OnHideClicked(true)}
                        />
                        <input
                            type="button"
                            value={"No"}
                            className="pop_button"
                            onClick={(e) => OnHideClicked(false)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Component;
