import axios from "axios";
import { UploadApi } from "services";

var fn = {};

fn.Upload = async (file, obj) => {
    return new Promise(async (resolve) => {
        let tmpFile = `imports/${obj.nfileName}.${obj.ext}`;
        let rslt = await UploadApi.GetSignedUrl({ fileName: tmpFile });
        if (rslt.status !== 100) return resolve({ status: 900, statusText: rslt.statusText });
        let urlInfo = rslt.data;
        rslt = await UploadToBucket(urlInfo.url, file, urlInfo.ContentType)
        return resolve(rslt);
    });
};

const UploadToBucket = async (url, data, type) => {

    const headers = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": type,
        },
    };

    return new Promise(async (resolve) => {
        try {
            const res = await axios.put(url, data, headers);
            if (res.status === 200) return resolve({ status: 100, data: res.request.responseURL });
            return resolve({ status: 900, statusText: res.statusText });
        } catch (err) {
            console.log(err);
            return resolve({ status: 900, statusText: err.message });
        }
    });
};

export default fn;
