import { React, Link } from "../common";

const menus1 = [
    {
        id: 1, label: "Import Excel", childs: false, links: [{ link: "/iloan" }]
    },
    {
        id: 2, label: "Loan Accounts", childs: false, links: [{ link: "/loans" }]
    },
    /* {
        id: 2, label: "Loan Accounts", childs: true, links: [
            { label: "List", link: "/loans" },
            { label: "Add Loan", link: "/aloan" }
        ]
    }, */
    {
        id: 3, label: "Teams", childs: false, links: [{ link: "/teams" }]
    },
    /* {
        id: 3, label: "Teams", childs: true, links: [
            { label: "List", link: "/teams" },
            { label: "Team Agents", link: "/tagent" },
            { label: "Team Loans", link: "/tloan" }
        ]
    }, */
    /* {
        id: 4, label: "Agents", childs: true, links: [
            { label: "List", link: "/agent" },
            { label: "Add Agent", link: "/aagent" }
        ]
    }, */

    {
        id: 4, label: "Users", childs: false, links: [{ link: "/users" }]
    },
    { id: 5, label: "Applications", links: [{ link: "/apps" }] },
    {
        id: 6, label: "Transactions", childs: true, links: [
            { label: "List", link: "/trans" },
            { label: "Upcoming", link: "/ftrans" }
        ]
    },
    { id: 7, label: "Reports", links: [{ link: "/report" }] },
    { id: 8, label: "Tokens", links: [{ link: "/token" }] },
    {
        id: 9, label: "E-Mandate", childs: true, links: [
            { label: "List", link: "/lnach" },
            { label: "New Request", link: "/rnach" }
        ]
    }
]

const menus = [
    { id: 1, label: "Import Excel", links: [{ link: "/iloan" }], admin: true },
    { id: 2, label: "Import Bank Statement", links: [{ link: "/ibank" }], admin: true },
    { id: 3, label: "Locations", links: [{ link: "/loc" }], admin: true },
    { id: 4, label: "Users", links: [{ link: "/users" }], admin: true },
    { id: 5, label: "Assign Loans", links: [{ link: "/aloan" }], admin: true },
    {
        id: 6, label: "E-Mandate", childs: true, links: [
            { label: "List", link: "/lnach" },
            { label: "New Request", link: "/rnach" }
        ], nach: true
    },
    { id: 7, label: "OTP Verifications", links: [{ link: "/otp" }], admin: true },
    { id: 8, label: "Agent Imports", links: [{ link: "/aimport" }], admin: true },
    { id: 9, label: "Collection Reports", links: [{ link: "/report" }], admin: true },
    { id: 10, label: "OTP Reports", links: [{ link: "/oreports" }], admin: true },
    { id: 11, label: "AC-Detailed Report", links: [{ link: "/acreports" }], admin: true }

]

const Component = ({ loc }) => {
    const [menuItems, setMenuItems] = React.useState([]);

    const ActiveClass = (menu, defClass) => {
        const foundLink = menu.links.findIndex((z) => z.link === loc) > - 1;
        return foundLink ? `${defClass} active` : defClass;
    }

    const IsActive = (menu) => {
        return menu.links.findIndex((z) => z.link === loc) > - 1;
    }

    const RenderMenu = ({ menu }) => {
        const link = menu.links[0].link;
        if (menu.childs) {
            return (
                <div className="dropdown">
                    {IsActive(menu) ? (
                        <>
                            <button className="dropbtn active"><div className="genericIcon active"></div>{menu.label}<div className="arrow_down_topnav"></div></button>
                            <div id={`menuitem_${menu.id}`} className="dropdown-content" style={{ display: 'block' }}>
                                {menu.links.map((x, index) => (
                                    <Link key={index} className={x.link === loc ? 'active' : undefined} to={x.link}>{x.label}</Link>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <button className="dropbtn"><div className="genericIcon"></div>{menu.label}<div className="arrow_down_topnav"></div></button>
                            <div id={`menuitem_${menu.id}`} className="dropdown-content">
                                {menu.links.map((x, index) => (
                                    <Link key={index} to={x.link}>{x.label}</Link>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            );
        }
        return (
            <div className="dropdown">
                <Link to={link}><button className={ActiveClass(menu, "dropbtn")}><div className={ActiveClass(menu, "genericIcon")}></div>{menu.label} </button></Link>
            </div>
        );
    }

    React.useEffect(() => {
        const role = sessionStorage.getItem('role');
        let mnus = [];
        if (role === 'admin') mnus = menus.filter((x) => x.admin);
        if (role === 'nach') mnus = menus.filter((x) => x.nach);
        setMenuItems(mnus);
    }, []);

    return (
        <>
            <div>
                <div className="leftNavBlock">
                    <div className="leftNavigation" id="myLeftnav">

                        {menuItems.map((x) => (
                            <RenderMenu key={x.id} menu={x} />
                        ))}

                        <Link to="#" className="icon" onClick={() => window.functionLeftNav()}></Link>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Component;