import React from "react";
import { Link } from "react-router-dom";
import helper from "../helper";

const Component = (props) => {
    const { name, value, options, uselabel, className, onBlurError,
        onInputChildChanged, errors, path } = props;

    const OnCheckClicked = (e) => {
        let _name = GetKeyName();
        helper.RemoveErrorIndex(errors, _name);
        if (onInputChildChanged) {
            let _val = uselabel ? e.label : e.value;
            onInputChildChanged(_name, _val);
        }

        if (onBlurError) onBlurError(_name, null, true);
    };

    const GetKeyName = () => {
        if (name) {
            return `${name.replace(/\./g, '_')}`;
        } if (path) {
            return `${path.replace(/\./g, '_')}`;
        } else {
            return `chkinput`;
        }
    };

    let errorStyle = {
        borderColor: "#b22929",
        borderStyle: "solid",
        borderWidth: 1,
    };

    return (
        <>
            <ul className={className}>
                {options &&
                    options.map((x, i) => {
                        return (
                            <li key={i} className={className}>
                                <Link
                                    to="#"
                                    className={
                                        x.value === value || x.text === value ? "current" : undefined
                                    }
                                    style={
                                        helper.GetErrorIndex(errors, GetKeyName()) > -1
                                            ? errorStyle
                                            : undefined
                                    }
                                    onClick={(e) => OnCheckClicked(x)}
                                >
                                    {x.text}
                                </Link>
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};

export default Component;
