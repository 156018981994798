const Component = ({ children, id, buttons, title, width, height, error }) => {

    width = width || "auto";
    height = height || "auto";

    return (
        <>
            <div id={id} className="pop_disable_bg" style={{ display: "block" }}>
                <div className="popup_dialog">
                    <div className="pop_head">{title}</div>
                    <div className="pop_contentArea" style={{ width, height }}>{children}</div>
                    {error && (
                        <div className="pop_statusLabel">
                            <div style={{ margin: 0 }} className="errormessage">{error}</div>
                        </div>
                    )}
                    <div className="pop_buttonLabel">
                        {
                            buttons.map((x, index) => (
                                <input
                                    key={index}
                                    type="button"
                                    value={x.label}
                                    className="pop_button"
                                    onClick={(e) => x.event(e)}
                                />
                            ))
                        }
                    </div>

                </div>
            </div>
        </>
    );
};
export default Component;
