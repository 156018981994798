import { React, Container, Support } from "common";
import { UsersApi, ReportsApi } from "services";
import CollctedList from "./colllist";
import NonCollctedList from "./noncolllist";

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const [agents, setAgents] = React.useState([]);
    const [reportType, setReportType] = React.useState("");

    const FetchResults = async () => {
        setAgents([]);
        let data = { page: 1, size: 99999, filters: { statusId: 1, typeId: 2 } }
        global.Busy(true);
        let rslt = await UsersApi.GetUsers(data);
        global.Busy(false);
        if (rslt.status === 100) {
            let _tmp = rslt.data.rows;
            _tmp.splice(0, 0, { _id: 0, name: "Select" });
            setAgents(_tmp);
        }
    }

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <Container {...props}>
            <div className="acreportheader">
                <div className="acreportheaderSelectField">
                    <select id="reportdrop" value={reportType} onChange={(e) => setReportType(e.target.value)}>
                        {Support.ReportTypes.map((item) => (
                            <option key={item.id} value={item.value}>
                                {item.text}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {parseInt(reportType) === 1 && (
                <CollctedList agentsList={agents} />
            )}

            {parseInt(reportType) === 2 && (
                <NonCollctedList agentsList={agents} />
            )}

        </Container>
    );
};

export default Component;