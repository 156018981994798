import API from "./api";
import { Session } from "common";

const GetLoanByLoanNumber = async (num) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.get(`loan/short/number/${num}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetLoans = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`/loan/admin/get`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SetLoanMapping = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`/loan/admin/map`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetLoanByLoanNumber, GetLoans, SetLoanMapping };
