import API from "./api";
import { Session } from "common";

const SingIn = async (input) => {
    try {
        const res = await API.post(`auth/admin`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetUsers = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`users/list`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetAllUsers = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`users/list/all`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddUsers = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`users`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetUsers, AddUsers, SingIn, GetAllUsers };
