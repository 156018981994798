import React from 'react';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';

const Pagination = props => {
    const {
        onPageChange,
        rowsCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;

    const paginationRange = usePagination({
        currentPage,
        rowsCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <>
            <ul className="pagination">
                {/* Left navigation arrow */}
                <Link><li
                    className={classnames('pagination', {
                        disabled: currentPage === 1
                    })}
                    onClick={onPrevious}
                >&lt;</li></Link>

                {paginationRange.map((pageNumber, index) => {
                    // If the pageItem is a DOT, render the DOTS unicode character
                    if (pageNumber === DOTS) {
                        return <Link key={index}><li className="pagination">&#8230;</li></Link>;
                    }

                    // Render our Page Pills
                    return (
                        <Link key={index}><li
                            className={classnames('pagination', {
                                active: pageNumber === currentPage
                            })}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </li></Link>
                    );
                })}
                {/*  Right Navigation arrow */}
                <Link><li
                    className={classnames('pagination', {
                        disabled: currentPage === lastPage
                    })}
                    onClick={onNext}
                >&gt;</li></Link>
            </ul>
        </>
    );
};

export default Pagination;