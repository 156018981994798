import {
    React, Link, Container,
    Helper, useNavigate, Session
} from "common";
import { UsersApi } from "services";

const Component = () => {

    const [row, setRow] = React.useState({});
    const [error, setError] = React.useState(null);

    const NavigateTo = useNavigate();

    const OnKeyDown = async (e) => {
        if (e.key === "Enter") OnLoginClicked(e);
    }

    const OnLoginClicked = async (e) => {
        e.preventDefault();
        setError(null);

        if (Helper.IsNullValue(row?.email) || Helper.IsNullValue(row?.password)) {
            setError("All fields are mandatory");
            return;
        } else if (!Helper.IsEmailValid(row?.email)) {
            setError("Provide valid email");
            return;
        }

        global.Busy(true);
        let rslt = await UsersApi.SingIn(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        Session.Store("user", rslt.data.user);
        Session.Store("role", rslt.data.role);
        Session.Store("userToken", rslt.data.token);

        if (rslt.data.role === 'admin') NavigateTo("/iloan");
        if (rslt.data.role === 'nach') NavigateTo("/lnach");
    }

    const OnInputChange = (e, name) => {
        e.preventDefault();
        setRow((prev) => ({
            ...prev,
            [name]: e.target.value
        }));
    }

    return (
        <Container login={true}>
            <div className="loginBlk">
                <ul className="loginContentBlk">
                    <li className="loginLeft">
                        <ul className="loginLeftHorBlk">
                            <li className="loginLeftHorBlk"></li>
                            <li className="loginLeftHorBlk">
                                <div className="loginHeadText">MCPL Admin Portal</div>
                                <div className="loginDivider"></div>
                                <div className="loginSupportText">A place for the MCPL Process Executive to track Loan Applications, Efficiently Managing Financial Processes and Streamlining Operations</div>
                            </li>
                            <li className="loginLeftHorBlk"></li>
                        </ul>
                    </li>
                    <li className="loginRight">
                        <form>
                            <div className="mcplLoginLogo mcplLogo"></div>
                            <div className="loginFormQ">
                                Email Address
                            </div>
                            <div className="loginFormTxtBox">
                                <input
                                    autoComplete={Helper.TimeStamp()}
                                    type="email"
                                    placeholder="Enter valid email"
                                    value={Helper.ToString(row?.email)}
                                    onChange={(e) => OnInputChange(e, 'email')}
                                    onKeyDown={OnKeyDown}
                                />
                            </div>
                            <div className="loginFormQ">
                                Password
                            </div>
                            <div className="loginFormTxtBox">
                                <input
                                    autoComplete={Helper.TimeStamp()}
                                    type="password"
                                    placeholder="Enter valid password"
                                    value={Helper.ToString(row?.password)}
                                    onChange={(e) => OnInputChange(e, 'password')}
                                    onKeyDown={OnKeyDown}
                                />
                            </div>

                            <Link to="#" onClick={(e) => OnLoginClicked(e)}><input
                                type="button"
                                value="Continue"
                                className="button_loginForm"
                            /></Link>

                            {error && <div className="errormessage_center">{error}</div>}
                        </form>
                    </li>
                </ul>
            </div>
            <div className="powered">Powered By: <Link to="https://uttarana.com" target="_blank">Moham Uttarana Pvt Ltd</Link></div>
        </Container>
    );
};

export default Component;