import Helper from "./index";
import * as XLSX from 'xlsx';

export default class ImportExcel {

    constructor(type, file, excelSchema, callback) {
        this.type = type,
            this.file = file;
        this.callback = callback;
        this.accounts = null;
        this.AgentsFields = excelSchema['Agents'];
    }

    columnsMatch = (obj, arr) => {
        return new Promise(async (resolve) => {
            debugger;
            const flds = Helper.ExtractFields(obj);
            const cflds = arr.filter((x) => x.required).map((z) => z.name);
            const foundFields = cflds.filter((x) => !flds.includes(x));
            return resolve(foundFields);
        });
    }

    sendSuccess = (msg, data) => { return { status: 100, statusText: msg, data }; }
    sendFailure = (msg) => { return { status: 900, statusText: msg }; }

    import = () => {
        return new Promise(async (resolve) => {

            if (Helper.IsNullValue(this.file)) return resolve(this.sendFailure("Select Excel File to import"));
            this.accounts = await this.readSelectedFile();
            this.callBack("Please wait processing the file...");
            debugger;
            let foundFields = await this.columnsMatch(this.AgentsFields, this.accounts['agent']);

            if (foundFields.length > 0) {
                this.callBack(`"${foundFields.join(",")}" columns does not match`);
                return resolve(this.sendFailure());
            }

            await this.validateLoans()
                .then(async (e) => {
                    return resolve(this.sendSuccess("File successfuly validated.", this.type));
                })
                .catch(({ m, e }) => {
                    this.callBack(m, e);
                    return resolve(this.sendFailure(m));
                });

            return resolve(this.sendSuccess("File successfuly validated.", this.type));

        })
    }

    callBack = (m, e) => {
        if (this.callback) this.callback(m, e);
    }

    readSelectedFile = async () => {
        return new Promise(async (resolve) => {
            let obj = {};
            const reader = new FileReader();
            this.callBack("Please wait reading the file...");
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {
                    type: 'binary',
                    cellDates: true,
                    cellNF: false,
                    dateNF: "yyyy-mm-dd",
                    cellText: false
                });
                for (let sheet of wb.SheetNames) {
                    const ws = wb.Sheets[sheet];
                    const rows = JSON.parse(JSON.stringify(XLSX.utils.sheet_to_json(ws, { defval: null })));
                    let finalData = [];
                    for (let row of rows) {
                        let newRow = {};
                        for (let col in row) {
                            let tCol = col.trim().replace(/\s+/g, '');
                            if (tCol === "FinstaReference") {
                                newRow[tCol] = row[col];
                            }
                        }
                        finalData.push(newRow);
                    }
                    obj[sheet] = finalData;
                }
                return resolve(obj);
            };
            reader.readAsBinaryString(this.file);
        })
    }

    validateLoans = async () => {
        return new Promise(async (resolve, reject) => {
            if (Helper.IsArrayNull(this.accounts['agent'])) {
                return reject({ m: "Agents Sheet does not exist" });
            } else if (Helper.IsArrayNull(this.accounts['agent'])) {
                return reject({ m: "Agents Sheet does not exist" });
            } else {
                this.callBack("Please wait processing the file...");
                let errors = [];
                const pattern = /^VLJP\d+$/;

                //  Check Finsta Referenece VLJP1800063
                let validRows = this.accounts['agent'];
                for (let t of validRows) {
                    const isValid = pattern.test(t['FinstaReference']);
                    if (!isValid) {
                        let obj = {
                            finstaReference: t['FinstaReference'],
                            Description: "Invalid Finsta Reference"
                        };
                        errors.push(obj);
                    }
                }


                if (errors.length > 0) {
                    return reject({ m: "Please fix the issues and try re-upload...", e: errors });
                }
                return resolve(this.accounts);
            }
        });
    }

};