import React from "react";
import helper from "../helper";

const Component = (props) => {
    const { name, value, valuetype, items, displayName, displayValue, json,
        dataRequired, requiredMessage, indexValue, onInputClicked,
        onInputChildChanged, index, borderColor, disabled, errors, width, path } = props;

    const tType = valuetype || "STRING";
    const tItems = items || [];
    const tReadOnly = disabled || false;
    const lWidth = width || null;
    let tIndex = index || 0;

    const OnSelectInputChanged = (e) => {
        if (onInputChildChanged) {
            let _name = GetKeyName();
            let _value = e.target.value;
            if (tType === 'BOOL' && !helper.IsNullValue(_value)) {
                _value = helper.ToBool(e.target.value);
            }
            let _results = {
                name: _name,
                value: _value,
                index: tIndex,
                selectedIndex: e.target.selectedIndex
            }
            if (json) {
                onInputChildChanged(_results);
            } else {
                onInputChildChanged(_results.name, _results.value, _results.index);
            }
        }
    }

    const GetKeyName = () => {
        if (name) {
            return `${name.replace(/\./g, '_')}`;
        } if (path) {
            return `${path.replace(/\./g, '_')}`;
        } else {
            return `cboinput`;
        }
    };

    const OnControlBlurChanged = async (e) => {
        e.preventDefault();
    }

    return (
        <>
            <select
                id={GetKeyName()}
                name={GetKeyName()}
                style={{
                    width: lWidth,
                    borderColor: helper.GetErrorIndex(errors, GetKeyName()) > -1 ? "#b22929" : borderColor
                }}
                disabled={tReadOnly}
                value={helper.ToString(value)}
                onChange={(e) => OnSelectInputChanged(e)}
                onClick={(e) => { if (onInputClicked) onInputClicked(GetKeyName()) }}
                onFocus={(e) => OnControlBlurChanged(e)}
                data-sel-req={dataRequired}
                data-sel-msg={requiredMessage}
                data-sel-idx={indexValue}
                data-sel-id={GetKeyName()}
            >
                {!helper.IsArrayNull(tItems) &&
                    tItems.map((x, index) => {
                        return (
                            <option key={index} value={x[displayValue]} disabled={x.disabled} >
                                {x[displayName]}
                            </option>
                        );
                    })}
            </select>
        </>
    );
}

export default Component;
