import { React } from "common";
import { UpiApi } from "services";
import PopupDialog from "screens/popups";

const Component = (props) => {

    const { data, onHideClicked } = props;
    const [error, setError] = React.useState(null);

    const OnCancelClicked = (e) => {
        e.preventDefault();
        if (onHideClicked) onHideClicked('revoke', true);
    }

    const OnConfirmClicked = async (e) => {
        e.preventDefault();
        setError(null);
        global.Busy(true);
        let rslt = await UpiApi.RevokeMandate(data._id);
        global.Busy(false);
        console.log(rslt);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        if (onHideClicked) onHideClicked('revoke', true);
    }

    const buttons = [{ label: "Confirm", event: OnConfirmClicked }, { label: "Cancel", event: OnCancelClicked }];

    return (
        <>
            <PopupDialog error={error} id={"revokePopup"} title={"Revoke E-Mandate"} buttons={buttons} width={450} >
                <div className="contentarea_table" style={{ border: 0 }} id="mandateRevoke">
                    Are you sure? You want revoke?
                </div>
            </PopupDialog>
        </>
    );
};
export default Component;
