const Frequencies = [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "ONETIME", value: "ONETIME" },
    { id: 2, text: "MONTHLY", value: "MONTHLY" },
    { id: 3, text: "WEEKLY", value: "WEEKLY" },
    { id: 4, text: "DAILY", value: "DAILY" },
    { id: 5, text: "QUARTERLY", value: "QUARTERLY" },
    { id: 6, text: "BIMONTHLY", value: "BIMONTHLY" },
    { id: 7, text: "FORTNIGHTLY", value: "FORTNIGHTLY" },
    { id: 8, text: "HALFYEARLY", value: "HALFYEARLY" },
    { id: 9, text: "YEARLY", value: "YEARLY" },
    { id: 10, text: "AS PRESENTED", value: "AS PRESENTED" }
];

const RuleTypes = [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "ON", value: "ON" },
    { id: 2, text: "BEFORE", value: "BEFORE" },
    { id: 3, text: "AFTER", value: "AFTER" }
];

const YesNoTypes = [
    { id: 1, text: "Yes", value: "Y" },
    { id: 2, text: "No", value: "N" },
];

const PurposeTypes = [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "00 - Default(P2P)", value: "00" },
    { id: 1, text: "01 - SEBI", value: "01", disabled: true },
    { id: 1, text: "02 - AMC", value: "02" },
    { id: 1, text: "03 - Travel", value: "03" },
    { id: 1, text: "04 - Hospitality", value: "04" },
    { id: 1, text: "05 - Hospital", value: "05" },
    { id: 1, text: "06 - Telecom", value: "06" },
    { id: 1, text: "07 - Insurance", value: "07" },
    { id: 1, text: "08 - Education", value: "08" },
    { id: 1, text: "09 - Gifting", value: "09" },
    { id: 1, text: "10 - Others", value: "10" },
    { id: 1, text: "14 - Recurring Mandate", value: "14" }
];

const AmountRules = [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "EXACT", value: "EXACT" },
    { id: 2, text: "MAX", value: "MAX" }
];

const ReportTypes = [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Collected List", value: 1 },
    { id: 2, text: "Non-Collected List", value: 2 }
];

export {
    Frequencies, RuleTypes, YesNoTypes, PurposeTypes, AmountRules, ReportTypes
};