import { React, DataGrid, TableColumns } from "common";
import PopupDialog from "screens/popups";
import { GetAllUsers } from "services/userApi";

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const { show, onPopupClosed } = props;
    const [rows, setRows] = React.useState({});
    let selectedItem = null;

    const OnCheckClicked = (e, data) => {
        selectedItem = null;
        if (e.target.checked) {
            selectedItem = data;
        }
    }

    const OnCancelClicked = (e) => {
        e.preventDefault();
        if (onPopupClosed) {
            onPopupClosed(false);
        }
    }

    const OnAddClicked = async (e) => {
        e.preventDefault();
        if (onPopupClosed) {
            onPopupClosed(true, selectedItem);
        }
    }

    const buttons = [{ label: "Select", event: OnAddClicked }, { label: "Cancel", event: OnCancelClicked }];

    const FetchResults = async () => {
        global.Busy(true);
        setRows([]);

        let data = { filters: { statusId: 1, typeId: 2 } };
        let rslt = await GetAllUsers(data);
        if (rslt.status === 100) {
            let _tmp = rslt.data || [];
            setRows(_tmp);
        }

        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    if (!show) return null;


    return (
        <>
            <PopupDialog id={"selectAgentPopup"} title={"Select Agent"} buttons={buttons} width={650} height={300} >
                <div className="contentarea_table">
                    {rows && rows.length > 0 && (
                        <DataGrid dataColumns={TableColumns.AgentColumns} keyId={"_id"} id="user"
                            curPage={1} pageSize={10} onCheckClicked={OnCheckClicked}
                            rowsCount={rows.length} dataRows={rows} />
                    )}
                </div>
            </PopupDialog>
        </>
    );
};
export default Component;
