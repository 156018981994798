import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

let fn = {};

let hostname = window.location.hostname;

let prodItems = ["admin.mohamcapital.com", "www.admin.mohamcapital.com"];
let stageItems = ["admin.mohamcapital.in", "www.admin.mohamcapital.in"];
let devItems = ["devadmin.mohamcapital.in", "www.devadmin.mohamcapital.in"];

fn.environment = 'dev';

if (hostname === "localhost") {
  //fn.APIUrl = "http://localhost:4000/";
  //fn.APIUrl = "https://core.mohamcapital.com/";
  // fn.APIUrl = "https://core.mohamcapital.in/";
  fn.APIUrl = "https://dev-core.mohamcapital.in/";
  fn.environment = 'dev';
} else if (prodItems.findIndex((x) => x === hostname) > -1) {
  fn.APIUrl = "https://core.mohamcapital.com/";
  fn.environment = 'rod';
} else if (stageItems.findIndex((x) => x === hostname) > -1) {
  fn.APIUrl = "https://core.mohamcapital.in/";
  fn.environment = 'staging';
} else if (devItems.findIndex((x) => x === hostname) > -1) {
  fn.APIUrl = "https://dev-core.mohamcapital.in/";
  fn.environment = 'dev';
}

fn.RedirectUrl = window.location.href.replace(/\/$/, "");

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const ssnRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
///^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/;

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

const googleApiKey = "AIzaSyCgQUVElQ_cUNrju5Mo9o3qbcx4A0HrlSo";

const validAadhaarRegex = RegExp(/^\d{12}$/);

const validPanRegex = RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

fn.Chapters = ["Chapter 7", "Chapter 11", "Chapter 12", "Chapter 3"];

fn.Chapters2 = [
  { id: 1, name: "Chapter 7", checked: false },
  { id: 2, name: "Chapter 11", checked: false },
  { id: 3, name: "Chapter 12", checked: false },
  { id: 4, name: "Chapter 3", checked: false },
];

fn.MilitaryServices = [
  {
    id: 1,
    name: "Currently serving on active duty with projected expiration date of service/tour",
    value: "Serving",
    checked: false,
  },
  {
    id: 2,
    name: "Currently retired, discharged, or separated from service",
    value: "Retired",
    checked: false,
  },
  {
    id: 3,
    name: "Only period of service was as a non-activated member of the Reserve or National Guard",
    value: "PeriodOfService",
    checked: false,
  },
  { id: 4, name: "Surviving spouse", value: "Spouse", checked: false },
];

fn.PreferendContact = [
  { id: 0, text: "Select", value: 0 },
  { id: 1, text: "Evening Phone", value: "Evening" },
  { id: 2, text: "Work Phone", value: "Work" },
  { id: 3, text: "Email", value: "Email" },
];

fn.CreditTypes = [
  { id: 0, text: "Select", value: 0 },
  { id: 1, text: "Very Good", value: "VeryGood" },
  { id: 2, text: "Good", value: "Good" },
  { id: 3, text: "Some Problem", value: "SomeProblem" },
  { id: 4, text: "Major Problem", value: "MajorProblem" },
  { id: 5, text: "I am not sure", value: "Unknown" },
];

fn.RelationTypes = [
  { id: 0, text: "Select", value: 0 },
  { id: 1, text: "Spouse", value: "Spouse" },
  { id: 2, text: "Co-owner", value: "Coowner" },
  { id: 3, text: "Other", value: "Other" },
];

/* return [
  { label: 'Yes', value: true, checked: false },
  { label: 'No', value: false, checked: false }
] */

fn.EthnicityOpts = () => {
  return [
    {
      label: "Hispanic or Latino",
      name: "hmdaEthnicityHispanicLatinoIndicator",
    },
    {
      label: "Not Hispanic or Latino",
      name: "hmdaEthnicityNotHispanicLatinoIndicator",
    },
    {
      label: "I do not wish to provide this information",
      name: "hmdaEthnicityDoNotWishIndicator",
    },
  ];
};

fn.EthnicitiesList = () => {
  return [
    { id: 1, label: "Mexican", name: "hmdaMexicanIndicator" },
    { id: 2, label: "Puerto Rican", name: "hmdaPuertoRicanIndicator" },
    { id: 3, label: "Cuban", name: "hmdaCubanIndicator" },
    {
      id: 4,
      label: "Other Hispanic or Latino - Print origin",
      name: "hmdaHispanicLatinoOtherOriginIndicator",
    },
    { id: 5, label: "Others", name: "hmdaOtherHispanicLatinoOrigin", type: 1 },
  ];
};

fn.AsiansList = () => {
  return [
    { id: 1, label: "Asian Indian", name: "hmdaAsianIndianIndicator" },
    { id: 2, label: "Chinese", name: "hmdaChineseIndicator" },
    { id: 3, label: "Filipino", name: "hmdaFilipinoIndicator" },
    { id: 4, label: "Japanese", name: "hmdaJapaneseIndicator" },
    { id: 5, label: "Korean", name: "hmdaKoreanIndicator" },
    { id: 6, label: "Vietnamese", name: "hmdaVietnameseIndicator" },
    {
      id: 7,
      label: "Other Asian - Print",
      name: "hmdaAsianOtherRaceIndicator",
    },
    {
      id: 8,
      label: "Other Asian - Print",
      name: "hmdaOtherAsianRace",
      type: 1,
    },
  ];
};

fn.PacificIslanders = () => {
  return [
    { id: 1, label: "Native Hawaiian", name: "hmdaNativeHawaiianIndicator" },
    {
      id: 2,
      label: "Guamanian Or Chamorro",
      name: "hmdaGuamanianOrChamorroIndicator",
    },
    { id: 3, label: "Samoan", name: "hmdaSamoanIndicator" },
    {
      id: 4,
      label: "Other Pacific Islander - Print race",
      name: "hmdaPacificIslanderOtherIndicator",
    },
    {
      id: 5,
      label: "Other Asian - Print",
      name: "hmdaOtherPacificIslanderRace",
      type: 1,
    },
  ];
};

fn.GenderTypesList = () => {
  return [
    {
      id: 1,
      key: "gendertypes",
      label: "Female",
      name: "hmdaGendertypeFemaleIndicator",
    },
    {
      id: 2,
      key: "gendertypes",
      label: "Male",
      name: "hmdaGendertypeMaleIndicator",
    },
    {
      id: 3,
      key: "gendertypes",
      label: "I do not wish to provide this information",
      name: "hmdaGendertypeDoNotWishIndicator",
    },
  ];
};

fn.Options = () => {
  return [
    { label: "Yes", value: true, checked: false },
    { label: "No", value: false, checked: false },
  ];
};

fn.TypeOfCredits = () => {
  return [
    { label: "Individual", value: "Individual", checked: false },
    { label: "Joint", value: "Joint", checked: false },
  ];
};

fn.NumberList = (start, end) => {
  let tmp = [];
  tmp.push({ text: "Select", value: 0 })
  for (let i = start; i <= end; i++) {
    tmp.push({ text: i, value: i })
  }
  return tmp;
};

fn.Deposits = () => {
  return [
    { label: "Deposited", value: true, checked: false },
    { label: "Not Deposited", value: false, checked: false },
  ];
};

fn.LienTypes = () => {
  return [
    { label: "First", value: "First", checked: false },
    { label: "Subordinate", value: "Subordinate", checked: false },
  ];
};

fn.Ownerships = () => {
  return [
    {
      label: "Have ownership share < 25%",
      value: "LessThan25Percent",
      checked: false,
    },
    {
      label: "Have ownership share > 25%",
      value: "GreaterThanOrEqualTo25Percent",
      checked: false,
    },
  ];
};

fn.IsNull = (e) => {
  if (e === undefined || e === null) return true;
  return false;
};

fn.IsNullValue = (e) => {
  if (e === undefined || e === null || e === "") return true;
  return false;
};

fn.ToBool = (e, defa) => {
  if (fn.IsNullValue(e)) return false;
  if (e === "true" || e === true) return true;
  if (e === "false" || e === false) return false;
};

fn.ToCheck = (e) => {
  if (fn.IsNullValue(e)) return false;
  if (e === "true" || e === true) return true;
  if (e === "false" || e === false) return false;
};

fn.ToUndefinedCheck = (e, bValue) => {
  if (fn.IsNullValue(e)) return undefined;
  if (e === "true" || e === true) return true;
  if (e === "false" || e === false) return false;
  return bValue ? e : undefined;
};

fn.ToString = (e, defa) => {
  if (fn.IsNull(e)) return defa ? defa : "";
  return e.toString();
};

fn.ToNumberString = (e, defa) => {
  if (fn.IsNull(e)) return defa ? defa : "";
  if (defa === e.toString()) return "";
  return e.toString();
};

fn.ToInteger = (e, defa) => {
  const re = /^[0-9\b]+$/;
  if (!fn.IsNullValue(e) && re.test(e)) {
    return parseInt(e);
  }
  return !fn.IsNullValue(defa) ? defa : -1;
};

fn.ToFloat = (e, defa, places) => {
  const re = /^[0-9\b]+$/;
  if (!fn.IsNullValue(e)) {
    let tmp = parseInt(e.toString().replace(".", ""));
    if (re.test(tmp)) {
      if (places) return parseFloat(e).toFixed(places);
      return parseFloat(e);
    }
  }
  return !fn.IsNullValue(defa) ? defa : -1;
};

fn.IsJSONEmpty = (e) => {
  if (fn.IsNull(e)) return true;
  for (var key in e) {
    if (Object.prototype.hasOwnProperty.call(e, key)) {
      return false;
    }
  }
  return true;
};

fn.IsArrayEmpty = (e) => {
  if (fn.IsNullValue(e)) return true;
  let tmp = e.filter((x) => !fn.IsNullValue(x));
  return tmp.length === 0;
};

fn.IsArrayNull = (e) => {
  if (fn.IsNull(e)) return true;
  if (e.length > 0) {
    return false;
  }
  return true;
};

fn.IsValidPhone = (e) => {
  if (fn.IsNullValue(e) || !phoneRegex.test(e)) {
    return false;
  }
  return true;
};

fn.IsValidSSN = (e) => {
  if (fn.IsNullValue(e) || !ssnRegex.test(e)) {
    return false;
  }
  return true;
};

fn.IsEmailValid = (e) => {
  if (fn.IsNullValue(e) || !validEmailRegex.test(e)) return false;
  return true;
};

fn.IsAadharValid = (e) => {
  if (fn.IsNullValue(e) || !validAadhaarRegex.test(e)) return false;
  return true;
};

fn.IsPanValid = (e) => {
  if (fn.IsNullValue(e) || !validPanRegex.test(e)) return false;
  return true;
};

fn.ToBoolean2 = (e, check) => {
  if (fn.IsNullValue(e)) return false;

  if (typeof e === "string") {
    return e.toString() === check.toString();
  } else if (typeof e === "number") {
    return parseInt(e) === parseInt(check);
  } else if (typeof e === "boolean") {
    return e === check;
  }

  return parseInt(e) === parseInt(check);
};

fn.IsValidateDate = (dateString, type) => {
  if (fn.IsNullValue(dateString)) return false;

  let operator = dateString.split("/");

  if (operator.length === 3) {
    // Extract the string into month, date and year
    let datepart = dateString.split("/");
    let month = parseInt(datepart[0]);
    let day = parseInt(datepart[1]);
    let year = parseInt(datepart[2]);

    if (month < 0 || month > 12) return false;

    // Create list of days of a month
    let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month === 1 || month > 2) {
      if (day > ListofDays[month - 1]) {
        ///This check is for Confirming that the date is not out of its range
        return false;
      }
    } else if (month === 2) {
      let leapYear = false;
      if ((!(year % 4) && year % 100) || !(year % 400)) {
        leapYear = true;
      }
      if (leapYear === false && day >= 29) {
        return false;
      } else if (leapYear === true && day > 29) {
        console.log("Invalid date format!");
        return false;
      }
    }

    if (type === "DOB") {
      let _year = new Date().getFullYear() - 21;
      let _year100 = new Date().getFullYear() - 100;

      if (year < _year100 || year > _year) {
        return false;
      }
    }
  } else {
    console.log("Invalid date format!");
    return false;
  }
  return true;
};

fn.AcceptedChars = (e, type) => {
  let evt = e ? e : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;

  var defaCodes = [8, 9, 13];
  var digitCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

  if (type === "EMAIL" || type === "PAN") return false;

  if (type === "TEXT") {
    if (digitCodes.indexOf(charCode) > -1) {
      e.preventDefault();
      return false;
    }
    return true;
  } else {
    Array.prototype.push.apply(defaCodes, digitCodes);
    if (type === "DATE" || type === "DOB") {
      Array.prototype.push.apply(defaCodes, [32, 40, 41, 45, 47]);
    } else if (type === "PHONE" || type === "NUMBERS") {
      Array.prototype.push.apply(defaCodes, [32, 40, 41, 45]);
    } else if (type === "FINANCE") {
      defaCodes.push(46);
    } else if (type === "NFINANCE") {
      defaCodes.push(45);
      defaCodes.push(46);
    }
  }

  if (defaCodes.indexOf(charCode) === -1) {
    e.preventDefault();
    return false;
  }

  if (type === "FINANCE") {
    var text = e.target.value;
    if (
      text.indexOf(".") !== -1 &&
      text.substring(text.indexOf(".")).length > 2 &&
      charCode !== 0 &&
      charCode !== 8 &&
      e.target.selectionStart >= text.length - 2
    ) {
      e.preventDefault();
      return false;
    }
  }

  return true;
};

fn.ToUSAPhone = (e) => {
  if (fn.IsNullValue(e)) return null;
  if (phoneRegex.test(e)) {
    return e.replace(phoneRegex, "($1) $2-$3");
  }
  return null;
};

fn.ToSSNNumber = (e) => {
  if (fn.IsNullValue(e) || e.replace(/-/g, '').length !== 9) return null;
  const ssn = e.replace(/[^\d]/g, "");
  if (ssn.length > 3) {
    if (ssn.length < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }
    if (ssn.length > 8) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
    }
  }
  return null;
};

fn.ToESTDate = (e, format) => {
  if (fn.IsNullValue(e)) return "";
  if (e.length < 10) return e;
  var dateParts = e.split("/");
  var day = parseInt(dateParts[0]);
  var month = parseInt(dateParts[1]) - 1;
  var year = parseInt(dateParts[2]);
  let dt = new Date(year, month, day);
  if (fn.IsNullValue(format)) return moment(new Date(dt));
  return moment(new Date(dt)).format(format);
};

fn.ToDate = (e, format, utc, incrementDay) => {
  if (fn.IsNullValue(e)) return "";
  if (e.length < 10) return e;
  if (fn.IsNullValue(format)) return moment(new Date(e));
  if (utc) {
    if (incrementDay) return moment(e).add(1, 'days').utc().format(format);
    return moment(e).utc().format(format);
  }
  return moment(new Date(e)).format(format);
};

fn.ToDateFormat = (e, format, utc) => {
  if (fn.IsNullValue(e)) return "";
  if (fn.IsNullValue(format)) return moment(new Date(e));
  if (utc) return moment(e).utc().format(format);
  return moment(new Date(e)).format(format);
};

fn.GetStringJoin = (row, names) => {
  if (fn.IsJSONEmpty(row)) return null;
  let items = [];
  names.forEach((elm) => {
    if (!fn.IsNullValue(row[elm])) items.push(row[elm]);
  });

  let _name = items.join(" ").trim();
  return _name;
};

fn.AddNumToDate = (input, type, num) => {
  let dt = new Date(input);

  switch (type) {
    case "D":
      dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + num);
      break;
    case "M":
      dt = new Date(dt.getFullYear(), dt.getMonth() + num, dt.getDate());
      break;
    case "Y":
      dt = new Date(dt.getFullYear() + num, dt.getMonth(), dt.getDate());
      break;
    default:
      break;
  }
  return dt;
};

fn.ErrorMessage = "You have some errors. Please fix and proceed.";
fn.SavedMessage = "Data is saved!";

fn.IsChecked = (item, name) => {
  return fn.ToInteger(item[name], -1) > -1;
};

fn.AddToList = (list, arr) => {
  let tmp = list || [];
  arr.forEach((elm) => {
    if (tmp[elm] === undefined || tmp[elm] === null) {
      tmp.push(elm);
    }
  });
  return tmp;
};

fn.GetValue = (obj, path) => {
  if (!path) return "";
  var properties = path.split(".");
  let _name = "";
  for (var i = 0; i < properties.length; i++) {
    var prop = properties[i];
    _name = prop;
    if (!obj || !obj.hasOwnProperty(prop)) {
      return { name: _name, value: "" };
    } else {
      obj = obj[prop];
    }
  }
  return { name: _name, value: obj };
};

fn.ModifyJsonObject = (obj, keyPath, value) => {
  let lastKeyIndex = keyPath.length - 1;
  for (var i = 0; i < lastKeyIndex; ++i) {
    let key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
};

fn.ModifyJsonArrayObject2 = (obj, path, value, options) => {
  let newRow = obj;
  if (options.IsArray) {
    let pos = -1;
    let _filter = options.filters;
    let listItems = newRow[options.path] || [];
    let obj = {};
    if (listItems.length > 0) {
      pos = listItems.findIndex((x) => x[_filter.name] === _filter.value);
      if (pos > -1) obj[options.path] = listItems[pos];
    }
    fn.ModifyJsonObject(obj, path.split("."), value);
    if (pos > -1) {
      listItems[pos] = obj[options.path];
    } else {
      listItems.push(obj[options.path]);
    }
    newRow[options.path] = listItems;
  } else {
    fn.ModifyJsonObject(obj, path.split("."), value);
  }
  return newRow;
};

fn.ModifyJsonArrayObject3 = (obj, path, value, options) => {
  let newRow = obj;
  if (options.IsArray) {
    let pos = -1;
    let _filter = options.filters;
    let listItems = newRow[options.path] || [];
    let obj = {};
    if (listItems.length > 0) {
      pos = listItems.findIndex(
        (x) => x[_filter.name] === _filter.value && x.index === options.index
      );
      if (pos > -1) obj[options.path] = listItems[pos];
    }
    fn.ModifyJsonObject(obj[options.path], path.split("."), value);
    if (pos > -1) {
      listItems[pos] = obj[options.path];
    } else {
      listItems.push(obj[options.path]);
    }
    newRow[options.path] = listItems;
  } else {
    fn.ModifyJsonObject(obj, path.split("."), value);
  }
  return newRow;
};

function BuildObject(obj, orgPath, value, newPath) {
  let tmpPath = newPath;
  if (fn.IsNull(tmpPath)) {
    newPath = orgPath.slice();
  }
  let path = newPath[0];
  let tPath;
  if (isNaN(path)) {
    if (fn.IsNull(obj[path])) {
      if (newPath.length > 1 && !isNaN(newPath[1])) {
        obj[path] = [];
        tPath = obj[path];
        newPath.shift();
      } else {
        if (Array.isArray(obj)) {
          let idx = obj.push({ [path]: {} });
          tPath = obj[idx - 1][path];
          // Added 06:23 PM
          obj = obj[idx - 1];
        } else {
          obj[path] = {};
          tPath = obj[path];
          // Added 06:23 PM
          //obj = obj[path];
        }
      }
    } else {
      tPath = obj[path];
      if (newPath.length > 1 && !isNaN(newPath[1])) {
        if (tPath.length > parseInt(newPath[1])) {
          tPath = tPath[parseInt(newPath[1])];
        } else {
          obj[path].push({});
          tPath = obj[path];
          tPath = tPath[parseInt(newPath[1])];
        }
        newPath.shift();
      }
    }
  }
  newPath.shift();
  if (newPath.length > 0) {
    BuildObject(tPath, orgPath, value, newPath);
  } else {
    obj[path] = value;
  }
}

fn.ModifyJsonArrayObject4 = (obj, path, value) => {
  let newRow = JSON.parse(JSON.stringify(obj));
  let splits = path.split("_");
  BuildObject(newRow, splits, value);
  return newRow;
};

fn.GetJsonValue = (obj, path) => {
  if (fn.IsNullValue(obj) || fn.IsJSONEmpty(obj)) return "";
  let item = JSON.parse(JSON.stringify(obj));
  let splits = path.split(".");
  for (let i = 0; i < splits.length; i++) {
    let e = splits[i];
    if (isNaN(e)) {
      item = item[e];
      if (fn.IsNull(item)) break;
      if (splits.length > i && !isNaN(splits[i + 1])) {
        item = item[parseInt(splits[i + 1])];
        if (fn.IsNull(item)) break;
      }
    }
  }
  return fn.ToString(item);
};

const filterItem = (x, filters) => {
  for (let i = 0; i < filters.length; i++) {
    let key = filters[i];
    if (key.value) {
      if (x[key.name] !== key.value) return false;
    }
  }
  return true;
};

fn.ModifyJsonArrayObject = (obj, path, value, options) => {
  let newRow = obj;
  if (options.IsArray) {
    let pos = -1;
    let _filter = options.filters;
    let listItems = newRow[options.path] || [];
    let obj = {};
    if (listItems.length > 0) {
      //pos = listItems.findIndex((x) => x[_filter.name] === _filter.value && x.index === options.index);
      pos = listItems.findIndex((x) => filterItem(x, _filter));
      if (pos > -1) obj[options.path] = listItems[pos];
    } else {
      obj[options.path] = {};
    }
    fn.ModifyJsonObject(obj[options.path], path && path.split("."), value);
    if (pos > -1) {
      listItems[pos] = obj[options.path];
    } else {
      listItems.push(obj[options.path]);
    }
    newRow[options.path] = listItems;
  } else {
    fn.ModifyJsonObject(obj, path && path.split("."), value);
  }
  return newRow;
};

fn.AddJsonArrayObject = (obj, name, json, options) => {
  let list = obj[name] || [];
  if (list.length > 0) {
    let _filter = options.filters;
    let pos = list.findIndex((x) => filterItem(x, _filter));
    if (pos < 0) list.push(json);
  } else {
    list.push(json);
  }
  return list;
};

fn.ToJsonObject = (path, value) => {
  let strips = path.split(".");
  let _field = "";
  for (let i = 0; i < strips.length; i++) {
    if (i === strips.length - 1) {
      _field = `${_field}{"${strips[i]}":"${value}"${"}".repeat(
        strips.length - 1
      )}`;
    } else {
      _field = _field === "" ? `"${strips[i]}":` : `${_field}{"${strips[i]}":`;
    }
  }
  return JSON.parse(`{${_field}}`);
};

fn.IsValueDefined = (e) => {
  if (fn.IsNull(e)) return undefined;
  if (e.toString().toLowerCase() === "true") return true;
  return false;
};

fn.GetYears = () => {
  let _years = [];
  _years.push({ value: -1, text: "Year" });
  for (let i = 0; i < 51; i++) {
    _years.push({ value: i, text: i.toString() });
  }
  return _years;
};

fn.GetMonths = () => {
  let _months = [];
  _months.push({ value: -1, text: "Mth" });
  for (let i = 0; i < 13; i++) {
    _months.push({ value: i, text: i.toString() });
  }
  return _months;
};

fn.AddNewRow = (obj, val) => {
  let tmp = obj || [];
  val["index"] = tmp.length;
  if (tmp.length === 0) {
    tmp.push(val);
  } else {
    tmp.push(val);
  }
  return tmp;
};

fn.MapObject = (obj1, obj2) => {
  if (obj1) {
    for (var k in obj1) {
      if ("object" !== typeof obj1[k]) {
        obj2[k] = obj1[k];
      } else {
        if (!obj2.hasOwnProperty(k)) {
          obj2[k] = obj1[k] instanceof Array ? [] : {};
        }
        fn.MapObject(obj1[k], obj2[k]);
      }
    }
  }
};

fn.GetErrorBackColor = (errors, fld) => {
  let _indx = -1;
  if (errors && errors.length > 0) {
    _indx = errors.findIndex((x) => x.field === fld);
    if (_indx === -1) {
      _indx = errors.findIndex((x) => x.field?.endsWith(fld));
    }
  }
  return _indx > -1 ? "#b22929" : undefined;
};

fn.GetErrorIndex = (errors, fld) => {
  let _indx = -1;
  if (errors && errors.length > 0) {
    _indx = errors.findIndex((x) => x.field === fld);
  }
  return _indx;
};

fn.RemoveErrorIndex = (errors, fld) => {
  let _indx = -1;
  if (errors && errors.length > 0) {
    _indx = errors.findIndex((x) => x.field === fld);
    if (_indx > -1) errors.splice(_indx, 1);
  }
};

fn.USCurrencyFormat = (e) => {
  if (fn.IsNullValue(e)) return "";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(e);
};

fn.ParseJSON = (input) => {
  if (fn.IsNullValue(input)) return input;
  return JSON.parse(input);
};

fn.NativeInputClick = (keyid) => {
  const input = document.getElementById(keyid);
  if (input) input.dispatchEvent(new Event("click", { bubbles: true }));
};

fn.AddSelectOption = (input, sortBy, sortField) => {
  if (sortBy) input = fn.SortBy(input, sortField);
  input.splice(0, 0, { _id: 0, value: "", text: "Select" });
};

fn.RemoveDuplicates = (arr, field) => {
  var cleaned = [];
  arr.forEach((item) => {
    let _name = item[field];
    let index = cleaned.findIndex((x) => x[field] === _name);
    if (index === -1) cleaned.push(item);
  });

  return cleaned;
}

fn.IsRequired = (e) => {
  if (fn.IsNullValue(e)) return false;
  return e.toString().toUpperCase() === "TRUE";
};

fn.GetUniqueValues = (value, index, self) => {
  return self.indexOf(value) === index;
};

fn.USCurrencyFormat = (e) => {
  if (fn.IsNullValue(e)) return "";
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(e);
};

fn.SortBy = (e, field) => {
  if (fn.IsArrayEmpty(e)) return e;
  return e.sort(function (a, b) {
    return a[field].localeCompare(b[field]);
  });
};

fn.GetApplicantCount = (row) => {
  let num = fn.ToInteger(row?.coApplicantCount);
  return num === -1 ? 0 : num;
};

fn.OnPopupInfoClicked = (e, name, title) => {
  e.preventDefault();
  let elm = document.getElementById(name);
  if (elm) {
    if (title) {
      elm.getElementsByTagName("label")[0].innerHTML = title
    }
    elm.style.display = "block";
  }
}

fn.IsFormValid = (id) => {
  let errors = [];
  const parent = document.getElementById(id);
  if (parent) {
    let elms = parent.querySelectorAll('[data-req="true"]');
    elms.forEach(elm => {
      if (fn.IsNullValue(elm.value)) {
        errors.push({ field: elm.id, value: elm.attributes['data-req-msg'].value });
      }
    });

    elms = parent.querySelectorAll('[data-opts="true"]');
    elms.forEach(elm => {
      let cAnchor = elm.querySelector('a.current');
      if (!cAnchor) {
        errors.push({
          field: elm.attributes['data-id'].value.replace(/\./g, '_'),
          value: elm.attributes['data-req-msg'].value
        });
      }
    });

    elms = parent.querySelectorAll('[data-sel-req="true"]');
    elms.forEach(elm => {
      let _value = parseInt(elm.attributes['data-sel-idx'].value);
      if (elm.selectedIndex < _value) {
        errors.push({
          field: elm.attributes['data-sel-id'].value,
          value: elm.attributes['data-sel-msg'].value
        });
      }
    });

    elms = parent.querySelectorAll('[data-error="true"]');
    elms.forEach(elm => {
      errors.push({ field: elm.id, value: elm.attributes['data-val-msg'].value });
    });

  }

  return { valid: errors.length === 0, errors: errors };
}

fn.CompareDate = (dt1, dt2, format) => {
  var startDate = moment(dt1, format);
  var endDate = moment(dt2, format);
  return parseInt(endDate.diff(startDate, 'days'));
}

fn.GetGUID = () => {
  return uuidv4().replace(/-/g, '');
}

fn.range = (start, end) => {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

fn.IsValidZipCode = async (e) => {
  if (fn.IsNullValue(e)) return true;
  const url = `https://api.zippopotam.us/us/${e}`;
  return await axios.get(url);
}

fn.GetDOBDate = (age) => {
  return new Date(moment().subtract(age, "years"));
}

fn.GetGooglePlaces = async (input) => {
  return new Promise(async (resolve) => {

    //const url = `https://maps.googleapis.com/maps/api/json?libraries=places&key=${googleApiKey}&address=${input}`;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?components=locality%3Asanta%20cruz%7Ccountry%3AES&key=${googleApiKey}`;
    var config = { method: 'get', url: url, headers: {} };
    await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        return resolve(true);
      })
      .catch(function (error) {
        console.log(error);
        return resolve(false);
      });

  });
}

fn.TimeStamp = () => { return new Date().valueOf(); }

fn.Padding = (val, len) => {
  return ("0".repeat(len) + val).slice(-len);
};

fn.RemoveString = (val) => {
  if (fn.IsNullValue(val)) return "";
  return val.toString().replace(/[^0-9\.]+/g, "");
};

// validate DD/MM/YYYY OR DD-MM-YYYY format
fn.IsDateValid = (val) => {
  if (fn.IsNullValue(val)) return false;
  let tmp = val.toString();
  let datepart = tmp.indexOf("-") > -1 ? val.toString().split("-") : val.toString().split("/");
  let day = parseInt(datepart[0]);
  let month = parseInt(datepart[1]);
  let year = parseInt(datepart[2]);

  // console.log(val, tmp, day, month, year);
  return true;
}

fn.ExtractFields = (e) => {
  let flds = [];
  for (var key in e) flds.push(key);
  return flds;
}

fn.INRCurrencyFormat = (e, nodecimals, nocurrency) => {
  if (fn.IsNullValue(e)) return "";
  let formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  if (nocurrency) {
    formatter = new Intl.NumberFormat("en-IN", {
      currency: "INR"
    });
  }
  let tmp = formatter.format(e);
  if (nodecimals) tmp = tmp.substring(0, tmp.indexOf("."));
  return tmp;
};

fn.IsEmptyOrZeroValue = (e) => {
  if (fn.IsNullValue(e)) return true;
  if (e === '0' || e === 0) return true;
  return false;
};

export default fn;
