import { React, Container, Helper, TableColumns, DataGrid, DatePicker, DropDown } from "common";
import { ReportsApi, UsersApi } from "services";

const Component = (props) => {

    const [initialize, setInitialize] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [row, setRow] = React.useState({});
    const [isGetreport, setIsGetreport] = React.useState(false);
    const [state, setState] = React.useState(false);
    const [agents, setAgents] = React.useState([]);
    const [totalAmount, setTotalAmount] = React.useState("");

    const [rowsCount, setRowsCount] = React.useState(10);
    const [curPage, setCurPage] = React.useState(1);
    const [pageSize] = React.useState(10);
    const [error, setError] = React.useState(null);

    const OnGetReportClicked = (e, download) => {
        setError(null);

        if (Helper.IsNullValue(row.agentId)) {
            setError("Please select Agent");
            return;
        }

        if (Helper.IsNullValue(row.fromDate)) {
            setError("Please select From Date");
            return;
        }

        if (Helper.IsNullValue(row.toDate)) {
            setError("Please select to Date");
            return;
        }

        if (row.fromDate > row.toDate) {
            setError("Please select valid Date Range");
            return;
        }

        FilterRows(download);
    }

    const OnInputChanged = async (path, value, index) => {
        setError(null);
        setIsGetreport(false);
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const FilterRows = async (download) => {
        setRows({});
        setIsGetreport(false);
        let filters = { status: 1 };
        let _row = { ...row };
        if (_row.agentId === "All") {
            filters.agentId = AllAgentsSelected();
        } else {
            filters.agentId = [_row.agentId];
        }

        if (!Helper.IsNullValue(row.fromDate)) {
            filters = { ...filters, fromDate: row.fromDate };
        }

        if (!Helper.IsNullValue(row.toDate)) {
            filters = { ...filters, toDate: row.toDate };
        }


        if (download) {
            let req = { filters, environment: Helper.environment };
            global.Busy(true);
            let rslt = await ReportsApi.DownloadOTPReports(req);
            global.Busy(false);
            if (rslt.status !== 100) {
                setError(rslt.statusText);
                return;
            }
            let filePath = rslt.file;
            await DownLoadExcel(filePath);
        } else {
            let req = { page: curPage, size: pageSize, filters };
            global.Busy(true);
            let rslt = await ReportsApi.GetOTPReports(req);
            global.Busy(false);
            if (rslt.status === 100) {
                let _tmp = rslt.data;
                setRows(_tmp.rows);
                setRowsCount(_tmp.recordCount);
                setTotalAmount(_tmp.totalAmount);
                setIsGetreport(true);
            }
        }

    }

    const DownLoadExcel = async (filePath) => {
        await fetch(filePath)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "Report.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    const AllAgentsSelected = () => {
        let _Ids = agents.map((x) => {
            if (x._id !== "All") return x._id;
        }).filter(x => !Helper.IsNullValue(x));
        return _Ids;
    }

    const GetAgentList = async () => {
        let data = { page: 1, size: 99999, filters: { statusId: 1, typeId: 2 } }
        global.Busy(true);
        let rslt = await UsersApi.GetUsers(data);
        if (rslt.status === 100) {
            let _tmp = rslt.data.rows;
            _tmp.splice(_tmp, 0, { _id: "All", name: "All" });
            OnInputChanged('agentId', "All");
            setAgents(_tmp);
        }

        global.Busy(false);
    };

    const OnPageChange = async (e) => {
        setCurPage(e);
        setInitialize(true);
    }

    if (initialize) {
        setInitialize(false);
        GetAgentList();
    }

    React.useEffect(() => {
        let _cur = curPage;
        if (curPage > 1) {
            _cur = ((curPage - 1) * pageSize) + 1;
        }
    }, [curPage, pageSize]);

    React.useEffect(() => { setInitialize(true); }, []);

    return (
        <>
            <Container {...props}>
                <div className="generic_headblk">
                    <div className="blkName">OTP Reports</div>
                    <div className="control_bar">
                        <div className="controlBarHeader">Agent Name:</div>
                        <div className="controlBarSelectField">
                            <DropDown
                                path={`agentId`}
                                value={row?.agentId}
                                onInputChildChanged={OnInputChanged}
                                items={agents}
                                displayName={"name"}
                                displayValue={"_id"}
                                dataRequired={false}
                                indexValue={1}
                            />
                        </div>
                        <div className="controlBarHeader">From Date:</div>
                        <div className="controlBarTxtField">
                            <DatePicker
                                id="fromDate"
                                name="fromDate"
                                value={Helper.ToString(row?.fromDate)}
                                formatDate={'DD-MM-YYYY'}
                                onInputChildChanged={OnInputChanged}
                                required={false}
                                requiredMessage={'Date is required'}
                                validation={false}
                                validationMessage={'Provide valid date'}
                                placeholder={'DD-MM-YYYY'}
                            />
                        </div>
                        <div className="controlBarHeader">To Date:</div>
                        <div className="controlBarTxtField">
                            <DatePicker
                                id="toDate"
                                name="toDate"
                                value={Helper.ToString(row?.toDate)}
                                formatDate={'DD-MM-YYYY'}
                                onInputChildChanged={OnInputChanged}
                                required={false}
                                requiredMessage={'Date is required'}
                                validation={false}
                                validationMessage={'Provide valid date'}
                                placeholder={'DD-MM-YYYY'}
                            />
                        </div>
                        <div className="mcpl_nav_btn">
                            <input type="button" value="Get Report" className="mcpl_btn"
                                onClick={(e) => OnGetReportClicked(e, false)}
                            />
                        </div>
                        <div className="mcpl_nav_btn">
                            <input type="button" value="Download Report" className="mcpl_btn"
                                onClick={(e) => OnGetReportClicked(e, true)}
                            />
                        </div>
                    </div>

                    {isGetreport &&
                        (<div className="control_bar">
                            <div className="controlBarHeader">
                                Report From : {Helper.ToDateFormat(row?.fromDate, "DD/MM/YYYY")} to  {Helper.ToDateFormat(row?.toDate, "DD/MM/YYYY")}
                                &nbsp; and Total Emi Amount : {Helper.INRCurrencyFormat(totalAmount)}
                            </div>
                        </div>
                        )}

                    {error && (
                        <ul className="mcpl_btn_blk">
                            <li className="mcpl_btn_successError" >
                                <div className="errormessage">{error}</div>
                            </li>
                        </ul>
                    )}
                </div>

                {rows && rows.length > 0 && (
                    <div className="contentarea_table">
                        <DataGrid dataColumns={TableColumns.OTPReports}
                            curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize} rowsCount={rowsCount} dataRows={rows} />
                    </div>
                )}
            </Container>
        </>
    )
}

export default Component;