import React from "react";
import RendorRoutes from "./routes";

global.Busy = (bBusy) => {
  var x = document.getElementById("busyloader");
  if (x) {
    if (bBusy) {
      x.className = "loader display-block";
    } else {
      x.className = "loader display-none";
    }
  }
};

const DefaultToken = () => {
  sessionStorage.setItem("userToken", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0ZDA1NmFhNDI2MTQyNGYyNTRkNGRmZCIsImVtYWlsSWQiOiJhZG1pbkBtb2hhbWNhcGl0YWwuaW4iLCJuYW1lIjoiQWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiJ9LCJpYXQiOjE2OTcxNjY3MTksImV4cCI6MTcwMDc2NjcxOSwic3ViIjoiNjRkMDU2YWE0MjYxNDI0ZjI1NGQ0ZGZkIn0.CQHdCMiHcM8H8wMf8O-L6jZZsmdss_tkASnvhsxIixc");
  sessionStorage.setItem("user", "Administrator");
  sessionStorage.setItem("role", "nach");
}

function App() {
  //DefaultToken();
  return (
    <RendorRoutes />
  );
}

export default App;
