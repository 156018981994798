import { React, Container, Helper, DataGrid, Pagination, TableColumns } from "common";
import PopupDialog from "screens/popups";
import { ImportApi } from "services";
import UploadFile from "helper/upload";

const formats = ["XLSX", "XLS", "CSV"];

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [importType, setImportType] = React.useState("");
    const [fileurl, setFileUrl] = React.useState(null);


    const [docInitlized, setDocInitlized] = React.useState(false);

    const [processing, setProcessing] = React.useState(null);
    const [formatError, setFormatError] = React.useState(null);
    const [validations, setValidations] = React.useState([]);
    const [importDocs, setImportDocs] = React.useState([]);

    const [excelSchema, setExcelSchema] = React.useState({});
    const [rowsCount, setRowsCount] = React.useState(0);
    const [curPage, setCurPage] = React.useState(1);
    const [pageSize] = React.useState(10);

    const OnOkClicked = (e) => {
        e.preventDefault();
        Helper.NativeInputClick("loanFile");
        setFormatError(null);
    }

    const OnFileSelected = async (e) => {
        e.preventDefault();
        setFileUrl(null);
        setValidations([]);
        let fileObj = e.target.files[0];
        if (!Helper.IsNullValue(fileObj)) {
            let _ext = fileObj.name.split(".").pop();
            let _index = formats.findIndex((x) => x === _ext.toUpperCase());
            if (_index > -1) {
                setFileUrl(e.target.files[0]);
            } else {
                setFormatError("Supported Format: " + formats.join(","));
            }
        }
    }

    const OnImportingStatus = (m, e) => {
        setProcessing(m);
        if (e) setValidations(e);
    }

    const OnUploadClicked = async (e) => {
        e.preventDefault();
        setFormatError(null);

        if (Helper.IsNullValue(importType)) {
            setProcessing("Select Bank to import statement");
            return;
        }

        if (Helper.IsNullValue(fileurl)) {
            setProcessing("Select file to import");
            return;
        }

        global.Busy(true);

        const { bankName } = bankAccounts.find((x) => x._id === importType);

        const dt = Helper.ToDateFormat(new Date(), "YYYYMMDDHHmmss");
        let _ext = fileurl.name.split(".").pop();
        const obj = { ext: _ext, ofileName: fileurl.name, nfileName: `${bankName}_${dt}` };

        setProcessing("Uploading the selected file.");
        let rslt = await UploadFile.Upload(fileurl, obj);

        if (rslt.status !== 100) {
            global.Busy(false);
            setProcessing(rslt.statusText);
            setFormatError(rslt.statusText);
            return;
        }
        const data = {
            type: "BANK",
            name: bankName,
            description: "Pending to process",
            statusId: 0,
            path: `${bankName}_${dt}.${_ext}`
        };

        await ImportApi.SetImportDocs(data);

        global.Busy(false);
        setImportType("");
        Helper.NativeInputClick("loanFile");
        setProcessing("File uploaded to server. Wait for process to complete");
        setDocInitlized(true);

        global.Busy(false);


    }

    const buttons = [{ label: "Ok", event: OnOkClicked }];

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await ImportApi.GetBankAccounts();
        if (rslt.status === 100) {
            let _tmp = rslt.data || {};
            setBankAccounts(_tmp);
        }

        global.Busy(false);

        await GetPendingDocs();
    };

    const GetPendingDocs = async () => {
        global.Busy(true);
        let req = { page: curPage, size: pageSize, type: "BANK" };
        let rslt = await ImportApi.GetImportDocs(req);
        setImportDocs([]);
        setRowsCount(0);
        if (rslt.status === 100) {
            let tmp = rslt.data;
            setRowsCount(tmp.recordCount);
            setImportDocs(tmp.rows);
        }
        global.Busy(false);
    };

    const OnPageChange = async (e) => {
        setCurPage(e);
        setDocInitlized(true);
    }

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    if (docInitlized) {
        setDocInitlized(false);
        GetPendingDocs();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <Container {...props}>
            <div className="generic_headblk">
                <div className="blkName">{props.label}</div>
            </div>
            <div className="contentarea_table">
                <div className="control_bar2">
                    <div className="controlBarHeader">Importing Type:</div>
                    <div className="controlBarSelectField">
                        <select name="filterType" value={importType} onChange={(e) => setImportType(e.target.value)}>
                            <option value="">Select</option>
                            {bankAccounts && bankAccounts.map((x) => (
                                <option value={x._id} key={x._id}>{x.bankName} - {x.bankAccountNumber}</option>
                            ))}
                        </select>
                    </div>
                    <div className="controlBarHeader">File:</div>
                    <div className="controlBarTxtField">
                        <input type="file" id="loanFile" name="loanFile" accept=".xlsx,.xls,.csv"
                            onClick={(e) => { e.target.value = null; }}
                            autoComplete={Helper.TimeStamp()} onChange={(e) => OnFileSelected(e)} />
                    </div>
                    <div className="mcpl_nav_btn">
                        <input type="button" value="Upload" className="mcpl_btn" onClick={(e) => OnUploadClicked(e)} />
                    </div>
                </div>

                {processing && (
                    <div className="mcplListTable">
                        <table>
                            <tbody>
                                <tr><td style={{ paddingLeft: 45, border: 0 }} >{processing}</td></tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            <div className="contentarea_table" style={{ marginTop: 10 }}>

                <div className="control_bar" style={{ margin: 5 }}>
                    <div className="controlPagination">
                        {rowsCount > 0 &&
                            <Pagination onPageChange={OnPageChange} className="pagination-bar"
                                currentPage={curPage} rowsCount={rowsCount} pageSize={pageSize} />
                        }
                    </div>
                </div>

                {importDocs && importDocs.length > 0 ? (
                    <DataGrid dataColumns={TableColumns.ImportDocColumns}
                        curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize}
                        rowsCount={rowsCount} dataRows={importDocs} />

                ) : (
                    <div className="mcplListTable">
                        <table>
                            <tbody>
                                <tr><td style={{ paddingLeft: 45, border: 0 }} >No pending documents found to process.</td></tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {formatError && (
                <PopupDialog id={"importPopup"} title={"Importing Bank Statement"} buttons={buttons} width={450} >
                    <div className="contentarea_table" style={{ border: 0 }} id="importPopup">
                        {formatError}
                    </div>
                </PopupDialog>
            )}
        </Container>
    );
};

export default Component;