import React from "react";
import Helper from "../helper";

const IsValidStatus = (list, name) => {
    const splits = list.split(",");
    return splits.indexOf(name) > -1;
}

const RenderRowColumn = ({ keyId, page, size, index, row, col, colId,
    onIconClicked, onLinkClicked, onCheckClicked, onRadioClicked, buttonstatusid, onDownLoadLinkClicked }) => {

    const GetBooleanText = (e) => {
        if (Helper.ToBool(e)) return "Yes";
        return "No";
    }

    const _page = (page - 1) * size - page + 1;
    const statusName = buttonstatusid ? row[buttonstatusid] : "";

    if (col.type === 'checkbox') {
        return (
            <td id={colId} style={{ width: col.width, padding: "10px 0px 0px 6px" }}>
                <input id={`${colId}_checkbox`} type="checkbox" onChange={(e) => onCheckClicked(e, row[keyId])} /><label htmlFor={`${colId}_checkbox`} />
            </td>
        )
    } else if (col.type === 'radio') {
        return (
            <td id={colId} style={{ width: col.width, padding: "10px 0px 0px 6px" }}>
                <input id={`${colId}_radio`} type="radio" name={col.groupname} value={row[keyId]} onClick={(e) => onRadioClicked(e)} /><label htmlFor={`${colId}_radio`} />
            </td>
        )
    }
    else if (col.type === 'INRLink') {
        return <td id={colId} style={{ width: col.width }}>
            <a onClick={(e) => onDownLoadLinkClicked(e, { colName: col["name"], data: row })}>{Helper.INRCurrencyFormat(row[col.name])}</a></td>;
    }

    else if (col.type === 'NumberLink') {
        return <td id={colId} style={{ width: col.width }}>
            <a onClick={(e) => onDownLoadLinkClicked(e, { colName: col["name"], data: row })}>{row[col.name]}</a></td>;
    }
    else if (col.type === 'index') {
        return <td id={colId} style={{ width: col.width }}>{Helper.Padding(_page + page + index, 5)}</td>
    } else if (col.type === 'date') {
        return <td id={colId} style={{ width: col.width }}>{Helper.ToDateFormat(row[col.name], "DD-MMM-YYYY", true)}</td>
    } else if (col.type === 'datetime') {
        return <td id={colId} style={{ width: col.width }}>{Helper.ToDateFormat(row[col.name], "DD-MMM-YYYY HH:mm:ss")}</td>
    } else if (col.type === 'boolean') {
        return <td id={colId} style={{ width: col.width }}>{GetBooleanText(row[col.name])}</td>
    } else if (col.type === 'number') {
        return <td id={colId} style={{ textAlign: "right", width: col.width }}>{row[col.name]}</td>;
    }
    else if (col.type === 'decimal') {
        return <td id={colId} style={{ textAlign: "right", width: col.width }}>{Helper.ToFloat(row[col.name], null, 2)}</td>;
    }
    else if (col.type === 'INR') {
        return <td id={colId} style={{ textAlign: "right", width: col.width }}>{Helper.INRCurrencyFormat(row[col.name])}</td>;
    }
    else if (col.type === 'buttons') {
        return <td id={colId} style={{ textAlign: "center", width: col.width }}>
            {col.icons.map((x, index) => (
                IsValidStatus(col.disabled[index], statusName) ? (
                    <i className={x} key={index} title={col.tips[index]}
                        style={{ color: "#EBEBE4", fontSize: 16, marginRight: 10, cursor: "pointer" }}></i>) : (
                    <i className={x} key={index} title={col.tips[index]}
                        onClick={(e) => onIconClicked(e, col.names[index], row)}
                        style={{ color: "#aa0800", fontSize: 16, marginRight: 10, cursor: "pointer" }}></i>)
            ))}
        </td>;
    } else if (col.type === 'hidden') {
        return <td id={colId} style={{ textAlign: "center", width: col.width }}>
            {col.icons.map((x, index) => (
                statusName === col.disabled[index] ? undefined : (
                    <i className={x} key={index} title={col.tips[index]}
                        onClick={(e) => onIconClicked(e, col.names[index], row)}
                        style={{ color: "#aa0800", fontSize: 16, marginRight: 10, cursor: "pointer" }}></i>)
            ))}
        </td>;
    } else if (col.type === 'upper') {
        return <td id={colId} style={{ width: col.width }}>{Helper.ToString(row[col.name]).toUpperCase()}</td>;
    } else if (col.type !== 'key') {
        if (col.type === 'link') {
            return <td id={colId} style={{ width: col.width }}>
                <a onClick={(e) => onLinkClicked(e, row)}>{Helper.ToString(row[col.name])}</a></td>;
        }
        return <td id={colId} style={{ width: col.width }}>{Helper.ToString(row[col.name])}</td>;
    }
    return null;
}

const Component = (props) => {
    const { id, dataColumns, dataRows, onIconClicked, onLinkClicked, keyId, onCheckClicked,
        pageSize, buttonstatusid, onDownLoadLinkClicked } = props;

    const curPage = props.curPage || 1;

    const OnIconClicked = (e, name, data) => {
        e.preventDefault();
        if (onIconClicked) onIconClicked(e, name, data);
    }

    const OnLinkClicked = (e, data) => {
        e.preventDefault();
        if (onLinkClicked) onLinkClicked(e, data);
    }

    const OnDownLoadLinkClicked = (e, data) => {
        e.preventDefault();
        if (onDownLoadLinkClicked) onDownLoadLinkClicked(e, data);
    }

    const OnCheckClicked = (e, data) => {
        if (onCheckClicked) onCheckClicked(e, data);
    }

    const OnRadioClicked = (e) => {
        if (onCheckClicked) onCheckClicked(e, e.target.value);
    }

    return (
        <>
            <div className="mcplTable_head">
                <table>
                    <tbody>
                        <tr id={`columns_${id}`}>
                            {dataColumns.map((x, index) => (
                                <td nowrap="true" key={index} style={{ width: x.width }}>{x.label}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mcplListTable">
                <table>
                    <tbody>
                        {dataRows.map((row, index) => (
                            <tr key={`row_${index}`}>
                                {dataColumns.map((col, cindex) => (
                                    <RenderRowColumn page={curPage} size={pageSize} buttonstatusid={buttonstatusid} keyId={keyId} onRadioClicked={OnRadioClicked}
                                        colId={`${id}_col_${index}_${cindex}`} onIconClicked={OnIconClicked} onLinkClicked={OnLinkClicked} onCheckClicked={OnCheckClicked}
                                        key={`${id}_col_${index}_${cindex}`} index={index} row={row} col={col} onDownLoadLinkClicked={OnDownLoadLinkClicked} />
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Component;