import {
    React, Helper, Support, RadioInput,
    TextInput, DropDown, Container, DatePicker
} from "common";
import { UpiApi, LoanApi } from "services";

const Component = (props) => {

    const [row, setRow] = React.useState({});
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [ruleTypeRequired, setRuleTypeRequired] = React.useState(false);
    const [acctValidate, setAcctValidate] = React.useState(false);

    const ruleTypeConditions = ['ONETIME', 'AS PRESENTED', 'DAILY'];

    const OnInputChanged = async (path, value, index, type) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        if (path === 'pattern') {
            setRuleTypeRequired(ruleTypeConditions.findIndex((x) => x === value) === -1);
        }
    }

    const OnBlurError = (name, val, clear) => {
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
        if (name === 'loanNumber') {
            CheckLoanNumber();
        }
    };

    const OnInputClicked = (name) => {
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('mandateCreate');
        let tErrors = errors || [];

        if (!row.payerAcctValidate) {
            tErrors.push({ field: 'payerAcctValidate', value: 'Payer Account Number Validation is required' });
        }


        if (!valid) {
            setErrors(tErrors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnCreateMandate = async (e) => {
        e.preventDefault();
        SetRuleValue();
        setError(null);

        if (!OnRecordValidate()) return;
        global.Busy(true);
        let rslt = await UpiApi.CreateMandate(row);
        setError(rslt.statusText);
        global.Busy(false);

    };

    const SetRuleValue = () => {

        let pattern = row?.pattern;
        let sdate = row?.selectedStartDate;

        if (!Helper.IsNullValue(pattern) && !Helper.IsNullValue(sdate)) {
            let rValue = "";
            var d = new Date(sdate);
            if (pattern === 'WEEKLY') {
                var days = {
                    'monday': 1, 'tuesday': 2, 'wednesday': 3,
                    'thursday': 4, 'friday': 5, 'saturday': 6, 'sunday': 7
                };
                const dName = rValue = Helper.ToDateFormat(sdate, "dddd");
                rValue = days[dName.toLowerCase()];
            } else if (pattern === 'FORTNIGHTLY') {
                if (d.getDate() > 0 && d.getDate() < 16) {
                    rValue = d.getDate();
                } else if (d.getDate() > 15 && d.getDate() < 32) {
                    rValue = d.getDate() - 15;
                }
            } else {
                const indx = ruleTypeConditions.findIndex((x) => x === pattern);
                if (indx === -1) rValue = d.getDate();
            }

            row.ruleValue = rValue;
        }


        const { text } = Support.PurposeTypes.find((x) => x.value === row?.purposeCode) || { text: undefined };
        row.purpose = text;
        row.actionType = 'CREATE';

        row.startDate = Helper.ToDateFormat(row?.selectedStartDate, "DDMMYYYY");
        row.endDate = Helper.ToDateFormat(row?.selectedEndDate, "DDMMYYYY");
    };

    const CheckLoanNumber = async () => {
        if (Helper.IsNullValue(row.loanNumber)) return;
        global.Busy(true);
        let rslt = await LoanApi.GetLoanByLoanNumber(row.loanNumber);
        global.Busy(false);
        if (rslt.status !== 100) {
            let _errors = errors || [];
            _errors.push({ field: 'loanNumber', value: 'Could not find the loan number' });
            setErrors(_errors);
            setError('Could not find the loan number');
        } else {
            const data = rslt.data;
            let lastRow = row || {};
            lastRow['loanId'] = data._id;
            lastRow['loanNumber'] = data.loanNumber;
            lastRow['customerName'] = data.customerName;
            lastRow['customerMobile'] = data.customerMobile;
            setRow(row);
        }
    }

    React.useEffect(() => {
        if (!ruleTypeRequired) {
            OnBlurError('ruleType', "", true);
        }
    }, [ruleTypeRequired]);

    React.useEffect(() => {
        setAcctValidate(false);
        if (!Helper.IsNullValue(row?.payerAcctValidate)) {
            if (row?.payerAcctValidate === 'Y') setAcctValidate(true);
        }
    }, [row?.payerAcctValidate]);

    return (
        <Container {...props}>
            <div className="generic_headblk">
                <div className="blkName">Request e-Mandate/e-Nach</div>
            </div>
            <div className="contentarea_table" style={{ border: 0 }}>
                <div className="mcplFormTable" id="mandateCreate">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="mcplFormContentHead">Loan Number</div><div className="mandatoryIcon">*</div>
                                    <div className="txtFieldStyle">
                                        <TextInput
                                            styles={{ height: "40px" }}
                                            errors={errors}
                                            path={`loanNumber`}
                                            value={row?.loanNumber}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            requiredMessage={'Loan Number is required'}
                                            validationMessage={"Loan Number is required"}
                                            dataRequired={true}
                                            required={true}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Mandate Name</div><div className="mandatoryIcon">*</div>
                                    <div className="txtFieldStyle">
                                        <TextInput
                                            styles={{ height: "40px" }}
                                            errors={errors}
                                            path={`name`}
                                            value={row?.name}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            requiredMessage={'Mandate Name is required'}
                                            validationMessage={"Mandate Name is required"}
                                            dataRequired={true}
                                            required={true}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Mandate Amount</div><div className="mandatoryIcon">*</div>
                                    <div className="txtFieldStyle">
                                        <TextInput
                                            styles={{ height: "40px" }}
                                            path={`amount`}
                                            value={row?.amount}
                                            valuetype={'FINANCE'}
                                            errors={errors}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            requiredMessage={'Mandate Amount is required'}
                                            validationMessage={'Mandate Amount is required'}
                                            dataRequired={true}
                                            required={true}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Amount Rule</div><div className="mandatoryIcon">*</div>
                                    <div className="selectStyle">
                                        <DropDown
                                            path={`amountRule`}
                                            value={row?.amountRule}
                                            items={Support.AmountRules}
                                            displayName={"text"}
                                            displayValue={"value"}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            errors={errors}
                                            dataRequired={true}
                                            required={true}
                                            indexValue={1}
                                            requiredMessage={"Amount Ruleis required"}
                                            validationMessage={"Amount Rule is required"}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Payer Name</div><div className="mandatoryIcon">*</div>
                                    <div className="txtFieldStyle">
                                        <TextInput
                                            styles={{ height: "40px" }}
                                            errors={errors}
                                            path={`payerName`}
                                            value={row?.payerName}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            requiredMessage={'Payer Name is required'}
                                            dataRequired={true}
                                            required={true}
                                            validationMessage={'Payer Name is required'}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="mcplFormContentHead">Payer Account Number Validation</div><div className="mandatoryIcon">*</div>
                                    <RadioInput
                                        className="radioMCPL"
                                        path={`payerAcctValidate`}
                                        value={row?.payerAcctValidate}
                                        options={Support.YesNoTypes}
                                        uselabel={false}
                                        onInputChildChanged={OnInputChanged}
                                        onBlurError={OnBlurError}
                                        errors={errors}
                                    />
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Payer Account Number</div>{acctValidate && <div className="mandatoryIcon">*</div>}
                                    <div className="txtFieldStyle">
                                        <TextInput
                                            styles={{ height: "40px" }}
                                            errors={errors}
                                            valuetype={'NUMBERS'}
                                            path={`payerAcctNum`}
                                            value={row?.payerAcctNum}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            requiredMessage={'Payer Account Number is required'}
                                            dataRequired={acctValidate}
                                            required={acctValidate}
                                            validationMessage={'Payer Account Number is required'}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Payer Virtual Address(UPI ID)</div><div className="mandatoryIcon">*</div>
                                    <div className="txtFieldStyle">
                                        <TextInput
                                            styles={{ height: "40px" }}
                                            errors={errors}
                                            path={`payerAddress`}
                                            value={row?.payerAddress}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            requiredMessage={'Payer Name is required'}
                                            validationMessage={'Payer Name is required'}
                                            dataRequired={true}
                                            required={true}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Rule Type</div>{ruleTypeRequired && <div className="mandatoryIcon">*</div>}
                                    <div className="selectStyle">
                                        <DropDown
                                            path={`ruleType`}
                                            value={row?.ruleType}
                                            items={Support.RuleTypes}
                                            displayName={"text"}
                                            displayValue={"value"}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            errors={errors}
                                            dataRequired={ruleTypeRequired}
                                            required={ruleTypeRequired}
                                            indexValue={1}
                                            requiredMessage={"Rule Type is required"}
                                            validationMessage={"Rule Type is required"}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">Frequency</div><div className="mandatoryIcon">*</div>
                                    <div className="selectStyle">
                                        <DropDown
                                            path={`pattern`}
                                            value={row?.pattern}
                                            items={Support.Frequencies}
                                            displayName={"text"}
                                            displayValue={"value"}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            errors={errors}
                                            dataRequired={true}
                                            indexValue={1}
                                            requiredMessage={"Frequency is required"}
                                            validationMessage={"Frequency is required"}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="mcplFormContentHead">Start Date</div><div className="mandatoryIcon">*</div>
                                    <div className="txtFieldStyle">
                                        <DatePicker
                                            errors={errors}
                                            path={`selectedStartDate`}
                                            placeholder="EMI Start Date"
                                            value={row?.selectedStartDate}
                                            valuetype={'DATE'}
                                            dateFormat="MM/dd/yyyy"
                                            required={true}
                                            requiredMessage={'Start Date is required'}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            minDate={new Date()}
                                            validationMessage={"Start Date is required"}
                                            dataRequired={true}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="mcplFormContentHead">End Date</div><div className="mandatoryIcon">*</div>
                                    <div className="txtFieldStyle">
                                        <DatePicker
                                            errors={errors}
                                            path={`selectedEndDate`}
                                            placeholder="EMI End Date"
                                            value={row?.selectedEndDate}
                                            valuetype={'DATE'}
                                            dateFormat="MM/dd/yyyy"
                                            required={true}
                                            dataRequired={true}
                                            validationMessage={'Emd Date is required'}
                                            requiredMessage={'Emd Date is required'}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            showYearDropdown={true}
                                            showMonthDropdown={true}
                                            minDate={Helper.AddNumToDate(new Date(), "M", 1)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ul className="mcpl_btn_blk">
                    <li className="mcpl_btn_blk">
                        <input type="button" value="Submit" className="mcpl_btn" onClick={(e) => OnCreateMandate(e)} />
                        {/* <input type="button" value="Cancel" className="mcpl_btn" /> */}
                    </li>
                    {error && (
                        <li className="mcpl_btn_successError" >
                            <div className="errormessage">{error}</div>
                        </li>
                    )}
                </ul>
            </div>
        </Container>
    );
};

export default Component;