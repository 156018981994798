var fn = {};

fn.Store = (key, value) => {
    sessionStorage.setItem(key, value);
};

fn.StoreAsync = async (key, value) => {
    return new Promise(async (resolve) => {
        sessionStorage.setItem(key, value);
        return resolve(true);
    });
};

fn.StoreJSON = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

fn.StoreJSONAsync = async (key, value) => {
    return new Promise(async (resolve) => {
        sessionStorage.setItem(key, JSON.stringify(value));
        return resolve(true);
    });
};

fn.RetrieveJSON = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
};

fn.RetrieveJSONAsync = async (key) => {
    return new Promise(async (resolve) => {
        return resolve(JSON.parse(sessionStorage.getItem(key)));
    });
};

fn.Retrieve = (key) => {
    return sessionStorage.getItem(key);
};

fn.RetrieveAsync = async (key) => {
    return new Promise(async (resolve) => {
        return resolve(sessionStorage.getItem(key));
    });
};

fn.Remove = (key) => {
    return sessionStorage.removeItem(key);
};

fn.RemoveAsync = async (key) => {
    return new Promise(async (resolve) => {
        return resolve(sessionStorage.removeItem(key));
    });
};

fn.IsLoggedIn = () => {
    const data = sessionStorage.getItem("userToken");
    return !(data === null || data === undefined || data.length === 0);
};

fn.GetHeaderAsync = async () => {
    const token = await fn.RetrieveAsync("userToken");
    return {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
    };
};

fn.ToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

fn.GetLoanIdAsync = async () => {
    let value = await fn.RetrieveAsync("loanid");
    return parseInt(value);
};

fn.GetUserIdAsync = async () => {
    let value = await fn.RetrieveAsync("user");
    if (value) {
        value = JSON.parse(value);
        return value._id;
    }
    return null;
};

fn.GetHeader = async () => {
    const token = await fn.RetrieveAsync("userToken");
    return {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    };
  };

export default fn;
