import Helper from "./index";
import * as XLSX from 'xlsx';

export default class ImportExcel {

    constructor(type, file, excelSchema, callback) {
        this.type = type;
        this.file = file;
        this.callback = callback;
        this.accounts = null;
        this.CustomerFields = excelSchema['Customer'];
        this.LoanAccountFields = excelSchema['LoanAccounts'];
        this.FuturePaymentsAccountsFields = excelSchema['FuturePaymentsAccounts'];
        this.PaymentAccountsFields = excelSchema['PaymentAccounts'];
    }

    columnsMatch = (obj, arr) => {
        return new Promise(async (resolve) => {
            const flds = Helper.ExtractFields(obj);
            const cflds = arr.filter((x) => x.required).map((z) => z.name);
            const foundFields = cflds.filter((x) => !flds.includes(x));
            return resolve(foundFields);
        });
    }

    sendSuccess = (msg, data) => { return { status: 100, statusText: msg, data }; }
    sendFailure = (msg) => { return { status: 900, statusText: msg }; }

    import = () => {
        return new Promise(async (resolve) => {
            if (Helper.IsNullValue(this.type)) return resolve(this.sendFailure("Select Importing type"));
            if (Helper.IsNullValue(this.file)) return resolve(this.sendFailure("Select Excel File to import"));
            this.accounts = await this.readSelectedFile();
            this.callBack("Please wait processing the file...");
            let foundFields;
            if (this.type === 'CustomersAndLoanAccounts') {
                if (Helper.IsArrayNull(this.accounts['Customers'])) {
                    this.callBack("Customers Sheet does not exist");
                    return resolve(this.sendFailure("Please fix the issues and try re-upload..."));
                } else if (Helper.IsArrayNull(this.accounts['LoanAccounts'])) {
                    this.callBack("LoanAccounts Sheet does not exist");
                    return resolve(this.sendFailure("Please fix the issues and try re-upload..."));
                }

                foundFields = await this.columnsMatch(this.accounts['Customers'][0], this.CustomerFields);
                if (foundFields.length > 0) {
                    this.callBack(`Customers: "${foundFields.join(",")}" columns does not match`);
                    return resolve(this.sendFailure());
                }

                foundFields = await this.columnsMatch(this.accounts['LoanAccounts'][0], this.LoanAccountFields);
                if (foundFields.length > 0) {
                    this.callBack(`LoanAccounts: "${foundFields.join(",")}" columns does not match`);
                    return resolve(this.sendFailure());
                }

                await this.validateLoans()
                    .then(async (e) => {
                        return resolve(this.sendSuccess("File successfuly validated.", this.type));
                    })
                    .catch(({ m, e }) => {
                        this.callBack(m, e);
                        return resolve(this.sendFailure(m));
                    });

            } else if (this.type === 'FeaturePayments') {

                foundFields = await this.columnsMatch(this.accounts['FeaturePayments'][0], this.FuturePaymentsAccountsFields);
                if (foundFields.length > 0) {
                    this.callBack(`"${foundFields.join(",")}" columns does not match`);
                    return resolve(this.sendFailure());
                }

                return resolve(this.sendSuccess("File successfuly validated.", this.type));
            }
            else if (this.type === 'Payments') {

                foundFields = await this.columnsMatch(this.accounts['Payments'][0], this.PaymentAccountsFields);
                if (foundFields.length > 0) {
                    this.callBack(`"${foundFields.join(",")}" columns does not match`);
                    return resolve(this.sendFailure());
                }

                return resolve(this.sendSuccess("File successfuly validated.", this.type));

            } else {
                return resolve(this.sendFailure("Invalid importing type"));
            }
        })
    }

    isLoanAccountExist = (e, z) => {
        return e.findIndex((x) => x.BorrowerCode === z.BorrowerCode) > -1;
    }

    callBack = (m, e) => {
        if (this.callback) this.callback(m, e);
    }

    readSelectedFile = async () => {
        return new Promise(async (resolve) => {
            let obj = {};
            const reader = new FileReader();
            this.callBack("Please wait reading the file...");
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {
                    type: 'binary',
                    cellDates: true,
                    cellNF: false,
                    dateNF: "yyyy-mm-dd",
                    cellText: false
                });
                for (let sheet of wb.SheetNames) {
                    const ws = wb.Sheets[sheet];
                    const rows = JSON.parse(JSON.stringify(XLSX.utils.sheet_to_json(ws, { defval: null })));
                    let finalData = [];
                    for (let row of rows) {
                        let newRow = {};
                        for (let col in row) {
                            if (col !== '__EMPTY') {
                                let tCol = col.trim();
                                newRow[col.trim()] = row[col];
                            }
                        }
                        finalData.push(newRow);
                    }
                    obj[sheet] = finalData;
                }
                return resolve(obj);
            };
            reader.readAsBinaryString(this.file);
        })
    }

    validateLoans = async () => {
        return new Promise(async (resolve, reject) => {
            if (Helper.IsArrayNull(this.accounts['Customers'])) {
                return reject({ m: "Customers Sheet does not exist" });
            } else if (Helper.IsArrayNull(this.accounts['LoanAccounts'])) {
                return reject({ m: "LoanAccounts Sheet does not exist" });
            } else {
                this.callBack("Please wait processing the file...");
                let errors = [];

                // Validate Loan accounts againest customers
                let validRows = this.accounts.Customers.filter((x) => !this.isLoanAccountExist(this.accounts.LoanAccounts, x));
                for (let t of validRows) {
                    let obj = {
                        BorrowerCode: t.BorrowerCode,
                        BorrowerName: t.BorrowerName,
                        Description: "Borrwer does not have the loan account"
                    };
                    errors.push(obj);
                }

                // Validate Phone Number
                validRows = this.accounts.Customers.filter((x) => !Helper.IsValidPhone(x.PhoneNo));
                for (let t of validRows) {
                    let obj = {
                        BorrowerCode: t.BorrowerCode,
                        BorrowerName: t.BorrowerName,
                        Description: t.PhoneNo ? `Invalid Borrwer's ${t.PhoneNo} number` : `Invalid Borrwer does not have the phone number`
                    };
                    errors.push(obj);
                }

                // Validate Aadhar Number
                validRows = this.accounts.Customers.filter((x) => !Helper.IsAadharValid(x.Aadhar));
                for (let t of validRows) {
                    let obj = {
                        BorrowerCode: t.BorrowerCode,
                        BorrowerName: t.BorrowerName,
                        Description: t.Aadhar ? `Invalid Borrwer's Aadhar ${t.Aadhar} number` : `Invalid Borrwer does not have the aadhar number`
                    };
                    errors.push(obj);
                }

                // Validate Gender
                validRows = this.accounts.Customers.filter((x) => ['M', 'F', 'T'].indexOf(Helper.ToString(x.Gender).toUpperCase()) === -1);
                for (let t of validRows) {
                    let obj = {
                        BorrowerCode: t.BorrowerCode,
                        BorrowerName: t.BorrowerName,
                        Description: t.Gender ? `Invalid Borrwer's Gender ${t.Gender}` : `Invalid Borrwer does not have the gender`
                    };
                    errors.push(obj);
                }

                if (errors.length > 0) {
                    return reject({ m: "Please fix the issues and try re-upload...", e: errors });
                }
                return resolve(this.accounts);
            }
        });
    }

};