import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import {
    Login, Users, CollectionReports, RequestNach, ListNach,
    Locations, ImportLoans, ImportBank, AssignLoans,
    OtpVerify, AgentImports, OtpReports, ACReports
} from "../screens";

const RouteLinks = [
    { id: 1, path: "", Component: Login, login: true },
    { id: 2, label: "Import Loans", path: "/iloan", Component: ImportLoans, Session: true, admin: true },
    { id: 3, label: "Import Bank Statement", path: "/ibank", Component: ImportBank, Session: true, admin: true },
    { id: 4, label: "Locations", path: "/loc", Component: Locations, Session: true, admin: true },
    { id: 5, label: "Users", path: "/users", Component: Users, Session: true, admin: true },
    { id: 6, label: "Request E-Mandate", path: "/rnach", Component: RequestNach, Session: true, nach: true },
    { id: 7, label: "E-Mandate List", path: "/lnach", Component: ListNach, Session: true, nach: true },
    { id: 8, label: "Assign Loans", path: "/aloan", Component: AssignLoans, Session: true, admin: true },
    { id: 9, label: "OTP Verifications", path: "/otp", Component: OtpVerify, Session: true, admin: true },
    { id: 10, label: "Agent Imports", path: "/aimport", Component: AgentImports, Session: true, admin: true },
    { id: 11, label: "Collection Reports", path: "/report", Component: CollectionReports, Session: true, admin: true },
    { id: 12, label: "OTP Reports", path: "/oreports", Component: OtpReports, Session: true, admin: true },
    { id: 13, label: "AC-Detailed Report", path: "/acreports", Component: ACReports, Session: true, admin: true }
];

function PrivateRoute({ children }) {
    let loggedin = sessionStorage.getItem("user");
    return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
    const loc = useLocation().pathname;
    let role = sessionStorage.getItem("role");
    const [routeList, setRouteList] = React.useState([]);

    React.useEffect(() => {
        let mnus = [];
        if (role === 'admin') {
            mnus = RouteLinks.filter((x) => x.admin);
        } else if (role === 'nach') {
            mnus = RouteLinks.filter((x) => x.nach);
        }
        setRouteList(mnus);
    }, [role]);

    return (
        <Routes>
            <Route path="/" exact element={<Login />} />
            {routeList.map((x) => {
                if (x.Session) {
                    return <Route key={x.id} path={"/" + x.path} exact
                        element={
                            <PrivateRoute>
                                <x.Component label={x.label} loc={loc} />
                            </PrivateRoute>
                        }
                    />
                } else {
                    return <Route key={x.id} path={"/" + x.path} exact
                        element={<x.Component label={x.label} loc={loc} />} />
                }
            })}
        </Routes>
    );
};

export default RendorRoutes;