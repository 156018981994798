import API from "./api";
import { Session } from "common";

const GetExcelSChema = async () => {
    try {
        const token = await Session.GetHeader();
        const res = await API.get(`import/schema`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetImportDocs = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`import/list`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SetImportDocs = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`import/doc`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetBankAccounts = async () => {

    try {
        const token = await Session.GetHeader();
        const res = await API.get(`common/bankaccounts`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }

}

export {
    GetImportDocs, GetExcelSChema, SetImportDocs, GetBankAccounts
};
