import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import helper from "../helper";

const Component = (props) => {

    const { index, path, name, value, valuetype, age, errors, dateFormat,
        showMonthDropdown, showYearDropdown, dropdownMode, minDate,
        validationMessage, dataRequired, required, requiredMessage, withPortal, portalId,
        onBlurError, onInputClicked, onInputChildChanged, placeholder } = props;

    const [startDate, setStartDate] = React.useState();
    const [maxDate, setMaxDate] = React.useState();
    const [controlName, setControlName] = React.useState("dtTextinput");

    const datepickerRef = React.useRef(null);

    const OnInputClicked = (e) => {
        e.preventDefault();
        if (onInputClicked) {
            onInputClicked(e.target.name);
        }
    }

    const OnBlurChanged = async (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        if (valuetype && valuetype === "DOB") {
            if (required && helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        }
    };

    const RaiseOnBlurError = (name, val, clear, data) => {
        global.Busy(false);
        if (onBlurError) {
            onBlurError(name, val, clear, data);
        }
    }

    const OnTextInputChanged = (value) => {
        setStartDate(value);
        if (onInputChildChanged) {
            let _name = GetKeyName();
            let _formatVal = helper.ToDateFormat(value, dateFormat?.toUpperCase());
            onInputChildChanged(_name, _formatVal, index);
        }
    };

    const GetKeyName = () => {
        let _name = `dtTextinput`;
        if (name) {
            _name = `${name.replace(/\./g, '_')}`;
        } if (path) {
            _name = `${path.replace(/\./g, '_')}`;
        }
        return _name;
    };

    React.useEffect(() => {
        setStartDate(null);
        if (!helper.IsNullValue(value)) {
            setStartDate(new Date(helper.ToDateFormat(value, dateFormat?.toUpperCase())));
        }
    }, [value]);


    React.useEffect(() => {
        const fn = () => {
            let _name = `dtTextinput`;
            if (name) {
                _name = `${name.replace(/\./g, '_')}`;
            } if (path) {
                _name = `${path.replace(/\./g, '_')}`;
            }
            setControlName(_name);
        };
        fn();
    }, [name, path]);

    React.useEffect(() => {
        let element = document.getElementById(controlName);
        element && element.addEventListener('keydown', (evt) => {
            if (evt.key === 'Tab') {
                if (datepickerRef.current.isCalendarOpen) {
                    datepickerRef.current.setOpen(false);
                }
            }
        });
    }, [controlName]);

    React.useEffect(() => {
        setMaxDate(null)
        if (!helper.IsNullValue(valuetype)) {
            if (valuetype === 'DOB') {
                setMaxDate(helper.GetDOBDate(age));
            }
        }
    }, [valuetype, age]);

    /* React.useEffect(() => {
        if (!showMonthDropdown && !showYearDropdown) {
            console.log("Show Title");
        }
    }, [showMonthDropdown, showYearDropdown]); */

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <input readOnly type="text" title={placeholder}
            placeholder={placeholder}
            data-req={dataRequired}
            data-req-msg={requiredMessage}
            data-val-msg={validationMessage}
            autoComplete={helper.TimeStamp()}
            id={GetKeyName()}
            name={GetKeyName()}
            style={{
                borderColor: helper.GetErrorBackColor(errors, GetKeyName()),
                paddingLeft: 20,
            }}
            onClick={onClick} ref={ref} value={value} />
    ));

    return (
        <>
            <div className={helper.GetErrorBackColor(errors, controlName) ? "datepickerError" : undefined} >

                <DatePicker
                    customInput={<CustomInput />}
                    dateFormat={dateFormat}
                    showMonthDropdown={showMonthDropdown}
                    showYearDropdown={showYearDropdown}
                    dropdownMode={dropdownMode}
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={(e) => OnTextInputChanged(e)}
                    onBlur={(e) => OnBlurChanged(e)}
                    onFocus={(e) => OnInputClicked(e)}
                    selected={startDate}
                    isClearable={true}
                    withPortal={withPortal}
                    portalId={portalId}
                />


                {/* <DatePicker
                    customInput={<CustomInput />}
                    autoComplete={helper.TimeStamp()}
                    ref={datepickerRef}
                    disabledKeyboardNavigation
                    dateFormat={dateFormat}
                    onBlur={(e) => OnBlurChanged(e)}
                    onFocus={(e) => OnInputClicked(e)}
                    id={controlName}
                    name={controlName}
                    showMonthDropdown={showMonthDropdown}
                    showYearDropdown={showYearDropdown}
                    dropdownMode={dropdownMode}
                    placeholderText={placeholder}
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={(e) => OnTextInputChanged(e)}
                    selected={startDate}
                    isClearable={true}
                /> */}
            </div>
        </>
    );
}

export default Component;
