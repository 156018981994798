import { React, Helper, TextInput, DatePicker } from "common";
import { UpiApi } from "services";
import PopupDialog from "screens/popups";


const Component = (props) => {

    const { data, onHideClicked } = props;
    const [row, setRow] = React.useState({});
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);

    const OnCancelClicked = (e) => {
        e.preventDefault();
        if (onHideClicked) onHideClicked('modify', true);
    }

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);

        let _errors = [];

        if (Helper.IsNullValue(row?.selectedStartDate) && Helper.IsNullValue(row?.amount)) {
            _errors.push({ field: "amount", value: "Enter valid amount." });
            _errors.push({ field: "selectedStartDate", value: "Enter valid start date." });
            setErrors(_errors);
            setError("Amount or Start date is required");
            return false;
        }

        return true;
    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        setError(null);
        global.Busy(true);
        row._id = data._id;
        let rslt = await UpiApi.ModifyMandate(row);
        global.Busy(false);
        console.log(rslt);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        if (onHideClicked) onHideClicked('modify', true);
    }

    const buttons = [{ label: "Update", event: OnSubmitClicked }, { label: "Cancel", event: OnCancelClicked }];

    const OnInputChanged = async (path, value) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
    }

    const OnBlurError = (name, val, clear) => {
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
        OnRecordValidate();
    };

    const OnInputClicked = (name) => {
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    return (
        <>
            <PopupDialog error={error} id={"revokePopup"} title={"Modify E-Mandate"} buttons={buttons} width={450} >
                <div className="contentarea_table" style={{ border: 0 }} id="mandateRevoke">
                    <div className="mcplFormTable">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="mcplFormContentHead">Payer Name</div>
                                        <div className="txtFieldStyle">
                                            <input type="text" readOnly value={Helper.ToString(data['payerName'])} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="mcplFormContentHead">Payer Address</div>
                                        <div className="txtFieldStyle">
                                            <input type="text" readOnly value={Helper.ToString(data['payerAddress'])} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="mcplFormContentHead">Mandate Amount</div>
                                        <div className="txtFieldStyle">
                                            <TextInput
                                                path={`amount`}
                                                value={row?.amount}
                                                valuetype={'FINANCE'}
                                                errors={errors}
                                                onInputChildChanged={OnInputChanged}
                                                onBlurError={OnBlurError}
                                                onInputClicked={OnInputClicked}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="mcplFormContentHead">Start Date</div>
                                        <div className="txtFieldStyle">
                                            <DatePicker
                                                errors={errors}
                                                path={`selectedStartDate`}
                                                placeholder="EMI Start Date"
                                                value={row?.selectedStartDate}
                                                valuetype={'DATE'}
                                                dateFormat="MM/dd/yyyy"
                                                onInputChildChanged={OnInputChanged}
                                                onBlurError={OnBlurError}
                                                onInputClicked={OnInputClicked}
                                                showYearDropdown={true}
                                                showMonthDropdown={true}
                                                minDate={new Date()}
                                                withPortal={true}
                                                portalId={"revokePopup"}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </PopupDialog>
        </>
    );
};
export default Component;
