import { Link } from "react-router-dom";
import "content/style/footer.scss";

const LoginFooter = () => {
    return (
        <>

            <div className="footerBlk">
                <ul className="footerLogin">
                    <li className="footerLogin"><Link to="#">Privacy Policy</Link>  &nbsp; | &nbsp;  <Link to="#">Terms of Use</Link></li>
                    <li className="footerLogin"> Moham Capital Pvt. Ltd. &copy; {new Date().getFullYear()}&nbsp; | &nbsp;All rights reserved.</li>
                </ul>
            </div>
        </>
    );
}

const InpageFooter = () => {
    return (
        <>
            <div className="footerBlk">
                <div className="footerInpage">
                    Moham Capital Pvt Ltd &copy; {new Date().getFullYear()} | All rights reserved.
                </div>
            </div>
        </>
    )
}

const Component = ({ type }) => {
    return type ? <LoginFooter /> : <InpageFooter />
}

export default Component;