import API from "./api";
import { Session } from "common";

const GetCollectionsReport = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/agentcoll`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetOTPReports = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/otp`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const DownloadOTPReports = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/otpdownload`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetCollectionsReportColl = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/agentcoll/coll`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetCollectionsReportAssigned = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/agentcoll/assigned`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetCollectionsReportCo = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/agentcoll/co`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetAgentCollectedReport = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/agentcoll/collected`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetAgentNonCollectedReport = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`reports/agentcoll/noncollected`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export {
    GetCollectionsReport,
    GetOTPReports,
    DownloadOTPReports,
    GetCollectionsReportColl,
    GetCollectionsReportAssigned,
    GetCollectionsReportCo,
    GetAgentCollectedReport,
    GetAgentNonCollectedReport,
};