import { React, Header, Footer } from "../common";

import MenuList from "menulist";

const Component = ({ children, login, loc, label }) => {

    if (login) {
        return (
            <>
                <div id="container">
                    <div id="content">
                        {children}
                    </div>
                </div>
                <Footer type={"Login"} />
            </>
        );
    }
    return (
        <>
            <div id="mcplPgContainer">
                <Header />
                <ul className="mcplLeftNavRightContentBlk">
                    <li className="mcplLeftNavBlk">
                        <MenuList loc={loc} />
                    </li>
                    <li className="mcplRightContentBlk">
                        <div className="widthfix_10">
                            <div id="mcplPgContentBlk">
                                <div className="genericContentBlk">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <Footer />
            </div>
        </>
    );
};

export default Component;