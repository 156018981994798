import { React, Container, Helper, DataGrid, Pagination, TableColumns } from "common";
import SelectUser from "./selectuser";
import { GetLoans, SetLoanMapping } from "services/loanApi";

const Component = (props) => {

    const [initialize, setInitialize] = React.useState(false);
    const [showUser, setShowUser] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [curPage, setCurPage] = React.useState(1);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [pageSize] = React.useState(10);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [loanNumber, setLoanNumber] = React.useState("");

    const OnPageChange = async (e) => {
        setCurPage(e);
        setInitialize(true);
    }

    const OnPopupClosed = async (e, val) => {
        setShowUser(false);
        if (e && selectedItems.length > 0 && !Helper.IsNullValue(val)) {
            global.Busy(true);
            let data = {
                agentId: val,
                loanList: selectedItems
            };
            let rslt = await SetLoanMapping(data);
            global.Busy(false);
            if (rslt.status !== 100) {
                alert(rslt.statusText);
            } else {
                setInitialize(true);
            }
        }
    }

    const OnAssignAgentClicked = async (e) => {
        e.preventDefault();
        setShowUser(true);
    }

    const OnCheckClicked = (e, data) => {
        let _selectedItems = selectedItems.filter((x) => x !== data);
        if (e.target.checked) {
            _selectedItems.push(data);
        }
        setSelectedItems(_selectedItems);
    }

    const FetchResults = async () => {
        setRows([]);

        global.Busy(true);

        let data = { page: curPage, size: pageSize };
        let filters = { statusId: 1 };

        if (!Helper.IsNullValue(loanNumber)) {
            filters = { ...filters, ["loanNumber"]: loanNumber };
        }

        data = { ...data, filters };

        let _data = { rows: [], recordCount: 0 };

        let rslt = await GetLoans(data);
        _data = rslt.data;
        if (rslt.status !== 100) {
            _data = { rows: [], recordCount: 0 };
        }

        let tmp = Helper.SortBy(_data.rows, 'loanNumber');
        _data.rows = tmp;
        setRowsCount(_data.recordCount);
        setRows(_data.rows);
        setTimeout(() => { global.Busy(false); }, 3000);

    }

    if (initialize) {
        setInitialize(false);
        setSelectedItems([]);
        FetchResults();
    }

    const OnSearchClicked = (e) => {
        e.preventDefault();
        setInitialize(true)
    }

    React.useEffect(() => { setInitialize(true) }, []);

    return (
        <Container {...props}>
            <div className="generic_headblk">
                <div className="blkName">{props.label}</div>
                <div className="control_bar">
                    <div className="controlBarHeader">Search By Loan Number:</div>
                    <div className="controlBarTxtField">
                        <input type="text" value={loanNumber} onChange={(e) => setLoanNumber(e.target.value)} />
                    </div>
                    <div className="mcpl_nav_btn">
                        <input type="button" value="Search" className="mcpl_btn"
                            onClick={(e) => OnSearchClicked(e)} />
                    </div>
                    <div className="mcpl_nav_btn">
                        <input type="button" value="Assign Agent" className="mcpl_btn" onClick={OnAssignAgentClicked} />
                    </div>
                    <div className="controlPagination">
                        {rowsCount > 0 &&
                            <Pagination onPageChange={OnPageChange} className="pagination-bar"
                                currentPage={curPage} rowsCount={rowsCount} pageSize={pageSize} />
                        }
                    </div>
                </div>
            </div>

            <div className="contentarea_table">
                {rows && rows.length > 0 ? (
                    <DataGrid dataColumns={TableColumns.LoanListColumns} keyId={"_id"} id="loan"
                        curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize} onCheckClicked={OnCheckClicked}
                        rowsCount={rowsCount} dataRows={rows} />

                ) : (
                    <div className="mcplListTable">
                        <table>
                            <tbody>
                                <tr><td style={{ paddingLeft: 45, border: 0 }} >No pending loans found to process.</td></tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            <SelectUser show={showUser} onPopupClosed={OnPopupClosed} />
        </Container>
    );
};

export default Component;