import { React, Container, DataGrid, Pagination, TableColumns } from "common";
import { CommonApi } from "services";

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [curPage, setCurPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);

    const FetchResults = async () => {
        global.Busy(true);
        setRow([]);
        setRowsCount(0);
        let req = { page: curPage, size: pageSize };
        let rslt = await CommonApi.GetOtps(req);
        if (rslt.status === 100) {
            let _tmp = rslt.data;
            setRowsCount(_tmp.recordCount);
            setRow(_tmp.rows);
        }
        global.Busy(false);
    };

    const OnPageChange = async (e) => {
        setCurPage(e);
        setInitlized(true);
    }

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        setInitlized(true);
    }, [curPage]);

    return (
        <Container {...props}>
            <div className="generic_headblk">
                <div className="blkName">OTP Verifications</div>
                {rowsCount > 0 &&
                    <Pagination onPageChange={OnPageChange} className="pagination-bar"
                        currentPage={curPage} rowsCount={rowsCount} pageSize={pageSize} />
                }
            </div>

            <div className="contentarea_table">
                <DataGrid dataColumns={TableColumns.OtpVerificationColumns}
                    curPage={curPage} onPageChange={OnPageChange} pageSize={pageSize} rowsCount={rowsCount} dataRows={row} />
            </div>

        </Container>
    );
};

export default Component;